class ValidationService {
  constructor(data) {
    this.error = data
  }

  get(property) {
    return this.error[property]
  }

  set(property, message) {
    this.error[property] = message
  }

  hasNoErrors() {
    let isValid = true

    for (const [key] of Object.entries(this.error)) {
      isValid = isValid && !this.has(key)
    }

    return isValid
  }

  has(property) {
    return this.error[property] !== null
  }

  clear() {
    for (const [key] of Object.entries(this.error)) {
      this.error[key] = null
    }
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
}

export default ValidationService
