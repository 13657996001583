import { createRouter, createWebHistory } from "vue-router"
import Dashboard from "@/views/layouts/Dashboard.vue"
import errorRoutes from "@/router/modules/error.js"
import guestRoutes from "@/router/modules/guest"
import AuthService from "@/services/AuthService"
import profileRoutes from "@/router/modules/profile.js"
import clientRoutes from "@/router/modules/client/index.js"
import courseRoutes from "@/router/modules/course/index.js"
import limboRoutes from "@/router/modules/limbo/index.js"

import { nextTick } from "vue"

const routes = [
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true, isLimbo: false },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/layouts/FullWidth.vue"
          ),
        children: [
          {
            path: "",
            name: "Dashboard",
            meta: { title: "My Dashboard" },
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ "@/views/dashboard/Index.vue"
              ),
          },
          ...profileRoutes,
          ...clientRoutes,
        ],
      },
      ...courseRoutes,
    ],
  },
  ...limboRoutes,
  ...guestRoutes,
  ...errorRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
})
let authService = new AuthService()

const DEFAULT_TITLE = "NACD Family Portal"

router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })

  if (authService.isGuest()) {
    window.Intercom("boot", {
      api_base: process.env.VUE_APP_INTERCOM_URL,
      app_id: process.env.VUE_APP_INTERCOM_KEY,
    })
  } else {
    let user = authService.getUser()

    window.Intercom("boot", {
      api_base: process.env.VUE_APP_INTERCOM_URL,
      app_id: process.env.VUE_APP_INTERCOM_KEY,
      name: user.full_name,
      email: user.user.email,
      user_id: user.user_id,
    })
  }

  window.Intercom("update")

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    authService.isGuest()
  ) {
    next({ name: "Login" })
  } else if (
    !authService.isGuest() &&
    (to.name === "Login" ||
      to.name === "ResetPassword" ||
      to.name === "SendResetPasswordLink")
  ) {
    next(from)
  } else {
    if (to.matched.some((record) => record.meta.isLimbo)) {
      if (!authService.isLimbo()) {
        next({ name: "Dashboard" })
      } else {
        next()
      }
    } else {
      if (authService.isLimbo()) {
        next({ name: "LimboSteps" })
      } else {
        next()
      }
    }
  }
})

export default router
