<template>
  <div>
    <BaseLabel :label="label" v-if="label" />

    <div class="flex relative">
      <input
        v-bind="$attrs"
        :autofocus="autofocus"
        :class="[isInvalid ? invalidClass : activeClass]"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
      />
      <span
        class="rounded-r-md inline-flex items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm"
      >
        <slot name="icon" />
      </span>
    </div>
  </div>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import { inputMixin, notRequiredFormFieldMixin } from "@/mixins/form.js"

export default {
  props: {
    addonLabel: {
      type: String,
      default: "",
    },
  },
  mixins: [inputMixin, notRequiredFormFieldMixin],
}
</script>

<style lang="scss" scoped></style>
