import ErrorService from "@/services/ErrorService.js"

export const namespaced = true

export const state = {
  notifications: [],
}

export const mutations = {
  HANDLE_ERROR(state, error) {
    state.notifications = [
      ...state.notifications,
      ...ErrorService.handle(error),
    ]
  },
  CLOSE(state, notification) {
    state.notifications = state.notifications.filter(
      (item) => item.id !== notification.id
    )
  },
  CLEAR(state) {
    state.notifications = []
  },
}

export const actions = {
  clear({ commit }) {
    commit("CLEAR")
  },
  handleError({ commit }, error) {
    commit("HANDLE_ERROR", error)
  },
  close({ commit }, notification) {
    commit("CLOSE", notification)
  },
}

export const getters = {}
