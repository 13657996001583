<template>
  <div class="shadow sm:rounded-md">
    <div class="px-4 py-5 bg-white sm:p-6">
      <slot />
    </div>
    <div class="px-4 py-3 bg-gray-50 text-center" :class="textAlign">
      <div class="sm:inline-flex sm:justify-center">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textAlign: {
      type: String,
      default: "sm:text-right",
    },
  },
}
</script>

<style lang="scss" scoped></style>
