export const namespaced = true

export const state = {
  educationalHistoryForm: null,
  educationalPlacementsForm: {},
  applicationFormEducationalPlacements: [],
}

export const mutations = {
  CREATE_EDUCATIONAL_HISTORY_FORM(state, form) {
    state.educationalHistoryForm = form
  },
  CREATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENTS(state, models) {
    state.applicationFormEducationalPlacements = models
  },
  CREATE_EDUCATIONAL_PLACEMENTS_FORM(state, models) {
    state.applicationFormEducationalPlacements.forEach((item) => {
      state.educationalPlacementsForm[item.id] = false
    })

    models.forEach((item) => {
      state.educationalPlacementsForm[item.id] = true
    })
  },
}

export const actions = {
  createEducationalHistoryForm({ commit }, educationalHistory) {
    commit("CREATE_EDUCATIONAL_HISTORY_FORM", {
      days_per_week: educationalHistory.days_per_week,
      hours_of_attendance: educationalHistory.hours_of_attendance,
      schools: educationalHistory.schools,
      educational_problems: educationalHistory.educational_problems,
      educational_diagnoses: educationalHistory.educational_diagnoses,
      exceptional_abilities: educationalHistory.exceptional_abilities,
      classes: educationalHistory.classes,
      adversely_events: educationalHistory.adversely_events,
      adversely_events_description:
        educationalHistory.adversely_events_description,
    })
  },
  createApplicationFormEducationalPlacements(
    { commit },
    applicationFormEducationalPlacements
  ) {
    commit(
      "CREATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENTS",
      applicationFormEducationalPlacements
    )
  },
  createEducationalPlacementsForm({ commit }, educationalPlacements) {
    commit("CREATE_EDUCATIONAL_PLACEMENTS_FORM", educationalPlacements)
  },
}

export const getters = {
  educationalPlacements(state) {
    let result = []

    for (const property in state.educationalPlacementsForm) {
      if (state.educationalPlacementsForm[property]) {
        result.push({ placement_id: parseInt(property) })
      }
    }

    return result
  },
}
