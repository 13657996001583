import clientEvaluationRoutes from "@/router/modules/client/evaluation.js"
import clientProgramRoutes from "@/router/modules/client/program.js"
import clientEvaluationVideoRoutes from "@/router/modules/client/evaluation-video.js"
import clientApplicationFormRoutes from "@/router/modules/client/application-form.js"
import clientReEvaluationRoutes from "@/router/modules/client/re-evaluation.js"

const clientRoutes = [
  {
    path: "/clients/:id",
    name: "ClientLayout",
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-layout" */ "@/views/client/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ClientView",
        meta: { title: "View Client" },
        component: () =>
          import(
            /* webpackChunkName: "client-view" */ "@/views/client/View.vue"
          ),
      },
      {
        path: "edit",
        name: "ClientEdit",
        meta: { title: "Edit Client" },

        component: () =>
          import(
            /* webpackChunkName: "client-edit" */ "@/views/client/Edit.vue"
          ),
      },
      {
        path: "contact-coach",
        name: "ClientContactCoach",
        meta: { title: "Contact Coach" },
        component: () =>
          import(
            /* webpackChunkName: "client-contact-coach" */ "@/views/client/ContactCoach.vue"
          ),
      },

      ...clientEvaluationRoutes,
      ...clientProgramRoutes,
      ...clientEvaluationVideoRoutes,
      ...clientApplicationFormRoutes,
      ...clientReEvaluationRoutes,
    ],
  },
]

export default clientRoutes
