export const namespaced = true

export const state = {
  developmentalHistoryForm: null,
}

export const mutations = {
  CREATE_DEVELOPMENTAL_HISTORY_FORM(state, form) {
    state.developmentalHistoryForm = form
  },
}

export const actions = {
  createDevelopmentalHistoryForm({ commit }, developmentalHistory) {
    commit("CREATE_DEVELOPMENTAL_HISTORY_FORM", {
      crawled_years: developmentalHistory.crawled_years,
      crawled_months: developmentalHistory.crawled_months,
      crept_years: developmentalHistory.crept_years,
      crept_months: developmentalHistory.crept_months,
      walked_years: developmentalHistory.walked_years,
      walked_months: developmentalHistory.walked_months,
      toilet_trained_years: developmentalHistory.toilet_trained_years,
      toilet_trained_months: developmentalHistory.toilet_trained_months,
      first_word_years: developmentalHistory.first_word_years,
      first_word_months: developmentalHistory.first_word_months,
      use_of_couplets_years: developmentalHistory.use_of_couplets_years,
      use_of_couplets_months: developmentalHistory.use_of_couplets_months,
      word_phrases_years: developmentalHistory.word_phrases_years,
      word_phrases_months: developmentalHistory.word_phrases_months,
      sentences_years: developmentalHistory.sentences_years,
      sentences_months: developmentalHistory.sentences_months,
      conversational_language_years:
        developmentalHistory.conversational_language_years,
      conversational_language_months:
        developmentalHistory.conversational_language_months,
      read_years: developmentalHistory.read_years,
      read_months: developmentalHistory.read_months,
      enjoy_watching_tv: developmentalHistory.enjoy_watching_tv,
      enjoy_being_read_to: developmentalHistory.enjoy_being_read_to,
      enjoy_reading_books: developmentalHistory.enjoy_reading_books,
      speech_problems: developmentalHistory.speech_problems,
      fine_motor_problems: developmentalHistory.fine_motor_problems,
      gross_motor_problems: developmentalHistory.gross_motor_problems,
      bed_wet: developmentalHistory.bed_wet,
      current_list_chores: developmentalHistory.current_list_chores,
      free_time_activities: developmentalHistory.free_time_activities,
    })
  },
}

export const getters = {}
