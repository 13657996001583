<template>
  <a
    :href="link"
    target="_blank"
    class="flex items-center justify-center w-48 text-white bg-black rounded-lg h-14"
  >
    <div class="mr-3">
      <BaseGoogleColorIcon class="h-8 w-8" />
    </div>
    <div>
      <div class="text-xs">GET IT ON</div>
      <div class="-mt-1 font-sans text-xl font-semibold">Google Play</div>
    </div>
  </a>
</template>

<script>
export default {
  data() {
    return {
      link: "https://play.google.com/store/apps/details?id=com.nacdfamily.nacdportal",
    }
  },
}
</script>

<style lang="scss" scoped></style>
