<template>
  <div class="border-l-4 p-4" :class="clasName" role="alert">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    className() {
      return `bg-${this.color}-200 border-${this.color}-600 text-${this.color}-600`
    },
  },
}
</script>

<style lang="scss" scoped></style>
