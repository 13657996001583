function props(route) {
  let orderByDefault = route.query.order_by_updated_at || "desc"

  if (
    route.query.order_by_status_name ||
    route.query.order_by_created_at ||
    route.query.order_by_date
  ) {
    orderByDefault = null
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,
    order_by_created_at: route.query.order_by_created_at || null,
    order_by_updated_at: orderByDefault || null,
    order_by_date: route.query.order_by_date || null,
  }
}

const clientReEvaluationRoutes = [
  {
    path: "re-evaluations",
    name: "ClientReEvaluationIndex",
    meta: { title: "Re-Evaluation Forms" },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-re-evaluation-index" */ "@/views/client/re-evaluation/Index.vue"
      ),
  },
  {
    path: "re-evaluations/:reEvaluationId",
    name: "ClientReEvaluationView",
    meta: { title: "View Re-Evaluation Form" },
    props: (route) => ({
      reEvaluationId: parseInt(route.params.reEvaluationId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-re-evaluation-view" */ "@/views/client/re-evaluation/View.vue"
      ),
  },
  {
    path: "re-evaluations/:reEvaluationId/edit",
    name: "ClientReEvaluationEdit",
    meta: { title: "Edit Re-Evaluation Form" },
    props: (route) => ({
      reEvaluationId: parseInt(route.params.reEvaluationId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-re-evaluation-edit" */ "@/views/client/re-evaluation/Edit.vue"
      ),
  },
]

export default clientReEvaluationRoutes
