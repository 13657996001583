import { SUCCESS, ERROR } from "@/constants"

class ErrorService {
  static addNotification(type, message) {
    let notifications = []

    notifications.push({
      id: Date.now() + Math.random() * 10000,
      type,
      message,
    })

    return notifications
  }

  static setSuccessNotification(message) {
    return this.addNotification(SUCCESS, message)
  }

  static setErrorNotification(message) {
    return this.addNotification(ERROR, message)
  }

  static handle(error) {
    let message = this.get(error)
    return this.setErrorNotification(message)
  }

  static get(error) {
    let message = ""

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      error.graphQLErrors.forEach((item) => {
        if (item.message === "validation") {
          let obj = item.extensions.validation
          for (const value of Object.values(obj)) {
            value.forEach((msg) => {
              message += `${msg}\n`
            })
          }
        } else if (item.message === "Unauthorized") {
          message = "Please refresh the page or sign out and then sign back in."
        } else {
          message += `${item.message}\n`
        }
      })
    } else {
      message = JSON.stringify(error)

      if (error.response && error.response.statusText) {
        message = error.response.statusText
      } else if (error.message) {
        message = error.message
      }
    }

    return message
  }
}

export default ErrorService
