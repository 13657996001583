import courseCategoryRoutes from "@/router/modules/course/category.js"
import courseActivityRoutes from "@/router/modules/course/activity.js"
import courseIntroActivityRoutes from "@/router/modules/course/intro.js"

const courseRoutes = [
  {
    path: "/courses",
    component: () =>
      import(
        /* webpackChunkName: "course-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseIndex",
        meta: { title: "Courses" },
        component: () =>
          import(
            /* webpackChunkName: "course-index" */ "@/views/course/Index.vue"
          ),
      },
      {
        path: ":id",
        name: "CourseView",
        meta: { title: "View Course" },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-view" */ "@/views/course/View.vue"
          ),
      },
    ],
  },
  ...courseCategoryRoutes,
  ...courseIntroActivityRoutes,
  ...courseActivityRoutes,
]

export default courseRoutes
