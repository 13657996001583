function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc"

  if (route.query.order_by_status_name || route.query.order_by_updated_at) {
    orderByDefault = null
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  }
}

const familyApplicationFormRoutes = [
  {
    path: "application-forms",
    name: "ProfileApplicationFormIndex",
    meta: { title: "Application Forms" },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "family-application-form-index" */ "@/views/profile/application-form/Index.vue"
      ),
  },
  {
    path: "application-forms/create",
    name: "ProfileApplicationFormCreate",
    meta: { title: "Create Application Form" },
    component: () =>
      import(
        /* webpackChunkName: "family-application-form-create" */ "@/views/profile/application-form/Create.vue"
      ),
  },
  {
    path: "application-forms/:id",
    name: "ProfileApplicationFormView",
    meta: { title: "View Application Form" },
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "family-application-form-view" */ "@/views/profile/application-form/View.vue"
      ),
  },
  {
    path: "application-forms/:id/edit",
    name: "ProfileApplicationFormEdit",
    meta: { title: "Edit Application Form" },
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "family-application-form-edit" */ "@/views/profile/application-form/Edit.vue"
      ),
  },
]

export default familyApplicationFormRoutes
