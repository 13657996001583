import { createApp } from "vue"
import App from "@/App.vue"
import router from "@/router"
import store from "@/store"
import directives from "@/directives/index.js"

import { apolloProvider } from "@/apollo"
import upperFirst from "lodash/upperFirst"
import camelCase from "lodash/camelCase"
import AuthService from "@/services/AuthService"
import baseComponents from "@/services/base-components"

import Pusher from "pusher-js"

import "nprogress/nprogress.css"
import "@/assets/css/tailwind.css"

const app = createApp(App)

directives(app)

baseComponents.forEach((item) => {
  item.keys().forEach((fileName) => {
    const componentConfig = item(fileName)
    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
    )
    app.component(componentName, componentConfig.default || componentConfig)
  })
})

app.config.globalProperties.$authService = new AuthService()

app.config.globalProperties.$pusher = new Pusher(
  process.env.VUE_APP_PUSHER_KEY,
  {
    cluster: "mt1",
    //   authEndpoint: "http://192.168.33.12/broadcasting/auth",
  }
)

Pusher.logToConsole = true

app.use(store).use(router).use(apolloProvider).mount("#app")
