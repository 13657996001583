const clientProgramRoutes = [
  {
    path: "programs/view",
    name: "ClientProgramView",
    meta: { title: "View Program" },
    props: (route) => ({
      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 100,

      group: route.query.group || null,
      description: route.query.description || null,
      notes: route.query.notes || null,
      frequency: route.query.frequency || null,
      duration: route.query.duration || null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-program-view" */ "@/views/client/program/View.vue"
      ),
  },
]

export default clientProgramRoutes
