<template>
  <BaseLabel :label="label" v-if="label" />

  <div class="flex" :class="[vertical ? 'flex-col' : 'items-center gap-3']">
    <BaseDisabledRadio
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value"
      :name="name"
      :modelValue="modelValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
