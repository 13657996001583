import { createStore } from "vuex"
import * as user from "@/store/modules/user.js"
import * as notification from "@/store/modules/notification.js"
import * as reEvaluation from "@/store/modules/re-evaluation.js"
import * as limboCredentials from "@/store/modules/limbo-credentials.js"
import * as applicationForm from "@/store/modules/application-form.js"
import * as applicationFormInfo from "@/store/modules/application-form-info.js"
import * as applicationFormAware from "@/store/modules/application-form-aware.js"
import * as applicationFormMedicalHistory from "@/store/modules/application-form-medical-history.js"
import * as applicationFormHealth from "@/store/modules/application-form-health.js"
import * as applicationFormBehaviour from "@/store/modules/application-form-behaviour.js"
import * as applicationFormSkill from "@/store/modules/application-form-skill.js"
import * as applicationFormDevelopmentalHistory from "@/store/modules/application-form-developmental-history.js"
import * as applicationFormEducationalHistory from "@/store/modules/application-form-educational-history.js"
import * as applicationFormGoal from "@/store/modules/application-form-goal.js"
import * as applicationFormAgreement from "@/store/modules/application-form-agreement.js"
import * as applicationFormPaymentAuthorization from "@/store/modules/application-form-payment-authorization.js"
import * as applicationFormChildInfo from "@/store/modules/application-form-child-info.js"
import * as applicationFormSidePage from "@/store/modules/application-form-side-page.js"
import * as applicationFormNotification from "@/store/modules/application-form-notification.js"
import * as applicationFormValidation from "@/store/modules/application-form-validation.js"

export default createStore({
  modules: {
    user,
    notification,
    reEvaluation,
    limboCredentials,
    applicationForm,
    applicationFormInfo,
    applicationFormAware,
    applicationFormMedicalHistory,
    applicationFormHealth,
    applicationFormBehaviour,
    applicationFormSkill,
    applicationFormDevelopmentalHistory,
    applicationFormEducationalHistory,
    applicationFormGoal,
    applicationFormAgreement,
    applicationFormPaymentAuthorization,
    applicationFormChildInfo,
    applicationFormSidePage,
    applicationFormNotification,
    applicationFormValidation,
  },
  state: {
    mobileHidden: true,
    hidden: true,
  },
  mutations: {
    TOGGLE_MOBILE_DROP_DOWN(state) {
      state.mobileHidden = !state.mobileHidden
    },
    CLOSE_MOBILE_DROP_DOWN(state) {
      if (!state.mobileHidden) {
        state.mobileHidden = !state.mobileHidden
      }
    },
    TOGGLE_PROFILE_DROP_DOWN(state) {
      state.hidden = !state.hidden
    },
    CLOSE_PROFILE_DROP_DOWN(state) {
      if (!state.hidden) {
        state.hidden = !state.hidden
      }
    },
  },
  actions: {
    toggleMobileDropdown({ commit }) {
      commit("TOGGLE_MOBILE_DROP_DOWN")
    },
    closeMobileDropdown({ commit }) {
      commit("CLOSE_MOBILE_DROP_DOWN")
    },
    toggleProfileDropdown({ commit }) {
      commit("TOGGLE_PROFILE_DROP_DOWN")
    },
    closeProfileDropdown({ commit }) {
      commit("CLOSE_PROFILE_DROP_DOWN")
    },
  },
})
