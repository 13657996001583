export const namespaced = true

export const state = {
  awareForm: null,
}

export const mutations = {
  CREATE_AWARE_FORM(state, form) {
    state.awareForm = form
  },
}

export const actions = {
  createAwareForm({ commit }, aware) {
    commit("CREATE_AWARE_FORM", {
      nacd_family: aware.nacd_family,
      nacd_family_description: aware.nacd_family_description,
      social_media: aware.social_media,
      social_media_description: aware.social_media_description,
      professional_group: aware.professional_group,
      professional_group_description: aware.professional_group_description,
      publication: aware.publication,
      publication_description: aware.publication_description,
      internet: aware.internet,
      internet_description: aware.internet_description,
      other: aware.other,
      other_description: aware.other_description,
    })
  },
}

export const getters = {}
