import {
  APPLICATION_FORM_CLIENT_PAGES,
  APPLICATION_FORM_MOTHER_PAGE,
  APPLICATION_FORM_FATHER_PAGE,
  APPLICATION_FORM_GUARDIAN_OTHER_PAGE,
  APPLICATION_FORM_SPOUSE_PARENT_GUARDIAN_PAGE,
  APPLICATION_FORM_REST_CLIENT_INFO_PAGES,
  APPLICATION_FORM_MEDICAL_HISTORY_PAGES,
  APPLICATION_FORM_HEALTH_PAGES,
  APPLICATION_FORM_BEHAVIOR_PAGES,
  APPLICATION_FORM_SKILLS_PAGES,
  APPLICATION_FORM_DEVELOPMENTAL_HISTORY_PAGES,
  APPLICATION_FORM_EDUCATIONAL_HISTORY_PAGES,
  APPLICATION_FORM_GOALS_PAGES,
  APPLICATION_FORM_AGREEMENT_PAGES,
  APPLICATION_FORM_PAYMENT_AUTHORIZATION_PAGES,
} from "@/constants/application-form.js"

export const namespaced = true

const INIT_PAGE_INDEX = 0

export const state = {
  pages: [],
  currentPageIndex: INIT_PAGE_INDEX,
}

export const mutations = {
  SET_PAGES(state, pages) {
    state.pages = pages
  },
  NEXT_PAGE(state) {
    if (state.currentPageIndex !== state.pages.length - 1) {
      state.currentPageIndex++
    }
  },
  PREVIOUS_PAGE(state) {
    if (state.currentPageIndex !== 0) {
      state.currentPageIndex--
    }
  },
  SET_CURRENT_PAGE_INDEX(state, index) {
    state.currentPageIndex = index
  },
}

export const actions = {
  setPages({ commit }, pages) {
    commit("SET_PAGES", pages)
  },
  setInitPageIndexWithoutValidation({ commit }) {
    commit("SET_CURRENT_PAGE_INDEX", INIT_PAGE_INDEX)
  },
  nextPage({ commit, dispatch, getters, rootState }) {
    let validationModule = rootState.applicationFormValidation

    if (getters.isClientPage) {
      dispatch("applicationFormValidation/validateClientInfoPage", null, {
        root: true,
      })
      if (validationModule.clientInfoValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isMotherPage) {
      dispatch("applicationFormValidation/validateMotherInfoPage", null, {
        root: true,
      })
      if (validationModule.motherInfoValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isFatherPage) {
      dispatch("applicationFormValidation/validateFatherInfoPage", null, {
        root: true,
      })
      if (validationModule.fatherInfoValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isGuardianPage) {
      dispatch("applicationFormValidation/validateGuardianInfoPage", null, {
        root: true,
      })
      if (validationModule.guardianInfoValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isSpousePage) {
      dispatch("applicationFormValidation/validateSpouseInfoPage", null, {
        root: true,
      })
      if (validationModule.spouseInfoValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isDevelopmentalHistoryPage) {
      dispatch("applicationFormValidation/validateDevelopmentalHistory", null, {
        root: true,
      })
      if (validationModule.developmentalHistoryValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isGoalsPage) {
      dispatch("applicationFormValidation/validateGoalPage", null, {
        root: true,
      })
      if (validationModule.goalValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isAgreementPage) {
      dispatch("applicationFormValidation/validateAgreementPage", null, {
        root: true,
      })
      if (validationModule.agreementValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isPaymentAuthorizationPage) {
      dispatch(
        "applicationFormValidation/validatePaymentAuthorizationPage",
        null,
        {
          root: true,
        }
      )
      if (validationModule.paymentAuthorizationValidation.hasNoErrors()) {
        commit("NEXT_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else {
      commit("NEXT_PAGE")
      dispatch("applicationForm/autoSave", null, { root: true })
    }
  },
  previousPage({ commit, dispatch, getters, rootState }) {
    let validationModule = rootState.applicationFormValidation

    if (getters.isClientPage) {
      dispatch("applicationFormValidation/validateClientInfoPage", null, {
        root: true,
      })
      if (validationModule.clientInfoValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isMotherPage) {
      dispatch("applicationFormValidation/validateMotherInfoPage", null, {
        root: true,
      })
      if (validationModule.motherInfoValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isFatherPage) {
      dispatch("applicationFormValidation/validateFatherInfoPage", null, {
        root: true,
      })
      if (validationModule.fatherInfoValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isGuardianPage) {
      dispatch("applicationFormValidation/validateGuardianInfoPage", null, {
        root: true,
      })
      if (validationModule.guardianInfoValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isSpousePage) {
      dispatch("applicationFormValidation/validateSpouseInfoPage", null, {
        root: true,
      })
      if (validationModule.spouseInfoValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isDevelopmentalHistoryPage) {
      dispatch("applicationFormValidation/validateDevelopmentalHistory", null, {
        root: true,
      })
      if (validationModule.developmentalHistoryValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isGoalsPage) {
      dispatch("applicationFormValidation/validateGoalPage", null, {
        root: true,
      })
      if (validationModule.goalValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isAgreementPage) {
      dispatch("applicationFormValidation/validateAgreementPage", null, {
        root: true,
      })
      if (validationModule.agreementValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else if (getters.isPaymentAuthorizationPage) {
      dispatch(
        "applicationFormValidation/validatePaymentAuthorizationPage",
        null,
        {
          root: true,
        }
      )
      if (validationModule.paymentAuthorizationValidation.hasNoErrors()) {
        commit("PREVIOUS_PAGE")
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    } else {
      commit("PREVIOUS_PAGE")
      dispatch("applicationForm/autoSave", null, { root: true })
    }
  },
  setCurrentPageIndex(
    { commit, dispatch, getters, rootState, rootGetters },
    index
  ) {
    if (!rootGetters["applicationForm/processing"]) {
      let validationModule = rootState.applicationFormValidation

      if (getters.isClientPage) {
        dispatch("applicationFormValidation/validateClientInfoPage", null, {
          root: true,
        })
        if (validationModule.clientInfoValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isMotherPage) {
        dispatch("applicationFormValidation/validateMotherInfoPage", null, {
          root: true,
        })
        if (validationModule.motherInfoValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isFatherPage) {
        dispatch("applicationFormValidation/validateFatherInfoPage", null, {
          root: true,
        })
        if (validationModule.fatherInfoValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isGuardianPage) {
        dispatch("applicationFormValidation/validateGuardianInfoPage", null, {
          root: true,
        })
        if (validationModule.guardianInfoValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isSpousePage) {
        dispatch("applicationFormValidation/validateSpouseInfoPage", null, {
          root: true,
        })
        if (validationModule.spouseInfoValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isDevelopmentalHistoryPage) {
        dispatch(
          "applicationFormValidation/validateDevelopmentalHistory",
          null,
          {
            root: true,
          }
        )
        if (validationModule.developmentalHistoryValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isGoalsPage) {
        dispatch("applicationFormValidation/validateGoalPage", null, {
          root: true,
        })
        if (validationModule.goalValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isAgreementPage) {
        dispatch("applicationFormValidation/validateAgreementPage", null, {
          root: true,
        })
        if (validationModule.agreementValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else if (getters.isPaymentAuthorizationPage) {
        dispatch(
          "applicationFormValidation/validatePaymentAuthorizationPage",
          null,
          {
            root: true,
          }
        )
        if (validationModule.paymentAuthorizationValidation.hasNoErrors()) {
          commit("SET_CURRENT_PAGE_INDEX", index)
          dispatch("applicationForm/autoSave", null, { root: true })
        }
      } else {
        commit("SET_CURRENT_PAGE_INDEX", index)
        dispatch("applicationForm/autoSave", null, { root: true })
      }
    }
  },
}

export const getters = {
  currentPage(state) {
    return state.pages[state.currentPageIndex]
  },
  isClientPage(state, getters) {
    return APPLICATION_FORM_CLIENT_PAGES.includes(getters.currentPage.id)
  },
  isMotherPage(state, getters) {
    return getters.currentPage.id === APPLICATION_FORM_MOTHER_PAGE
  },
  isFatherPage(state, getters) {
    return getters.currentPage.id === APPLICATION_FORM_FATHER_PAGE
  },
  isGuardianPage(state, getters) {
    return getters.currentPage.id === APPLICATION_FORM_GUARDIAN_OTHER_PAGE
  },
  isSpousePage(state, getters) {
    return (
      getters.currentPage.id === APPLICATION_FORM_SPOUSE_PARENT_GUARDIAN_PAGE
    )
  },
  isRestClientInfoPage(state, getters) {
    return APPLICATION_FORM_REST_CLIENT_INFO_PAGES.includes(
      getters.currentPage.id
    )
  },
  isMedicalHistoryPage(state, getters) {
    return APPLICATION_FORM_MEDICAL_HISTORY_PAGES.includes(
      getters.currentPage.id
    )
  },
  isHealthPage(state, getters) {
    return APPLICATION_FORM_HEALTH_PAGES.includes(getters.currentPage.id)
  },
  isBehaviorPage(state, getters) {
    return APPLICATION_FORM_BEHAVIOR_PAGES.includes(getters.currentPage.id)
  },
  isSkillsPage(state, getters) {
    return APPLICATION_FORM_SKILLS_PAGES.includes(getters.currentPage.id)
  },
  isDevelopmentalHistoryPage(state, getters) {
    return APPLICATION_FORM_DEVELOPMENTAL_HISTORY_PAGES.includes(
      getters.currentPage.id
    )
  },
  isEducationalHistoryPage(state, getters) {
    return APPLICATION_FORM_EDUCATIONAL_HISTORY_PAGES.includes(
      getters.currentPage.id
    )
  },
  isGoalsPage(state, getters) {
    return APPLICATION_FORM_GOALS_PAGES.includes(getters.currentPage.id)
  },
  isAgreementPage(state, getters) {
    return APPLICATION_FORM_AGREEMENT_PAGES.includes(getters.currentPage.id)
  },
  isPaymentAuthorizationPage(state, getters) {
    return APPLICATION_FORM_PAYMENT_AUTHORIZATION_PAGES.includes(
      getters.currentPage.id
    )
  },
}
