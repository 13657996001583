import { ref } from "vue"
import moment from "moment"

export const namespaced = true

export const state = {
  paymentAuthorizationForm: null,
  paymentAuthorizationDate: {
    date: null,
  },
}

export const mutations = {
  CREATE_PAYMENT_AUTHORIZATION_FORM(state, value) {
    state.paymentAuthorizationForm = value
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null
    state[dateForm][field] = value
  },
}

export const actions = {
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  createPaymentAuthorizationForm({ commit }, paymentAuthorization) {
    commit("SET_DATE_FIELD", {
      model: paymentAuthorization,
      field: "date",
      dateForm: "paymentAuthorizationDate",
    })

    commit("CREATE_PAYMENT_AUTHORIZATION_FORM", {
      client_name: paymentAuthorization.client_name
        ? paymentAuthorization.client_name
        : "",
      responsible_party: paymentAuthorization.responsible_party
        ? paymentAuthorization.responsible_party
        : "",
      street_address: paymentAuthorization.street_address
        ? paymentAuthorization.street_address
        : "",
      zip_code: paymentAuthorization.zip_code
        ? paymentAuthorization.zip_code
        : "",
      signature: paymentAuthorization.signature
        ? paymentAuthorization.signature
        : "",
      signature_print: paymentAuthorization.signature_print
        ? paymentAuthorization.signature_print
        : "",
      date: paymentAuthorization.date,
    })
  },
}

export const getters = {}
