<template>
  <p class="text-sm text-red-500 -bottom-6">
    {{ message }}
  </p>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>
