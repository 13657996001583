import LimboDashboard from "@/views/layouts/LimboDashboard.vue"

const limboRoutes = [
  {
    path: "/limbo",
    component: LimboDashboard,
    meta: { requiresAuth: true, isLimbo: true },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "limbo-full-width-layout" */ "@/views/layouts/LimboFullWidth.vue"
          ),
        children: [
          {
            path: "",
            name: "LimboSteps",
            meta: { title: "Limbo Steps" },
            component: () =>
              import(
                /* webpackChunkName: "limbo-dashboard" */ "@/views/limbo/steps/Index.vue"
              ),
          },
        ],
      },
    ],
  },
]

export default limboRoutes
