function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc"

  if (route.query.order_by_status_name || route.query.order_by_updated_at) {
    orderByDefault = null
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  }
}

const clientApplicationFormRoutes = [
  {
    path: "application-forms",
    name: "ClientApplicationFormIndex",
    meta: { title: "Application Forms" },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-application-form-index" */ "@/views/client/application-form/Index.vue"
      ),
  },
  {
    path: "application-forms/:applicationFormId",
    name: "ClientApplicationFormView",
    meta: { title: "View Application Form" },
    props: (route) => ({
      applicationFormId: parseInt(route.params.applicationFormId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-application-form-view" */ "@/views/client/application-form/View.vue"
      ),
  },
  {
    path: "application-forms/:applicationFormId/edit",
    name: "ClientApplicationFormEdit",
    meta: { title: "Edit Application Form" },
    props: (route) => ({
      applicationFormId: parseInt(route.params.applicationFormId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-application-form-view" */ "@/views/client/application-form/Edit.vue"
      ),
  },
]

export default clientApplicationFormRoutes
