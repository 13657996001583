import { ref } from "vue"
import moment from "moment"

export const namespaced = true

export const state = {
  infoForm: null,
  motherInfoForm: null,
  fatherInfoForm: null,
  guardianInfoForm: null,
  spouseInfoForm: null,

  infoDob: {
    date_of_birth: null,
  },
  motherInfoDob: {
    date_of_birth: null,
  },
  fatherInfoDob: {
    date_of_birth: null,
  },
  guardianInfoDob: {
    date_of_birth: null,
  },
  spouseInfoDob: {
    date_of_birth: null,
  },
}

export const mutations = {
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null
    state[dateForm][field] = value
  },
  CREATE_INFO_FORM(state, { formName, form }) {
    state[formName] = form
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
}

export const actions = {
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  createInfoForm({ commit }, info) {
    commit("CREATE_INFO_FORM", {
      formName: "infoForm",
      form: getInfoForm(info),
    })

    commit("SET_DATE_FIELD", {
      model: info,
      field: "date_of_birth",
      dateForm: "infoDob",
    })
  },
  createMotherInfoForm({ commit }, motherInfo) {
    commit("CREATE_INFO_FORM", {
      formName: "motherInfoForm",
      form: getInfoForm(motherInfo),
    })

    commit("SET_DATE_FIELD", {
      model: motherInfo,
      field: "date_of_birth",
      dateForm: "motherInfoDob",
    })
  },
  createFatherInfoForm({ commit }, fatherInfo) {
    commit("CREATE_INFO_FORM", {
      formName: "fatherInfoForm",
      form: getInfoForm(fatherInfo),
    })

    commit("SET_DATE_FIELD", {
      model: fatherInfo,
      field: "date_of_birth",
      dateForm: "fatherInfoDob",
    })
  },
  createGuardianInfoForm({ commit }, guardianInfo) {
    commit("CREATE_INFO_FORM", {
      formName: "guardianInfoForm",
      form: getInfoForm(guardianInfo),
    })

    commit("SET_DATE_FIELD", {
      model: guardianInfo,
      field: "date_of_birth",
      dateForm: "guardianInfoDob",
    })
  },
  createSpouseInfoForm({ commit }, spouseInfo) {
    commit("CREATE_INFO_FORM", {
      formName: "spouseInfoForm",
      form: getInfoForm(spouseInfo),
    })

    commit("SET_DATE_FIELD", {
      model: spouseInfo,
      field: "date_of_birth",
      dateForm: "spouseInfoDob",
    })
  },
}

export const getters = {}

function getInfoForm(model) {
  return {
    name: model ? model.name : null,
    address: model ? model.address : null,
    city: model ? model.city : null,
    state: model ? model.state : null,
    zip_code: model ? model.zip_code : null,
    country: model ? model.country : null,
    occupation: model ? model.occupation : null,
    date_of_birth: model ? model.date_of_birth : null,
    phone: model ? model.phone : null,
    phone_home: model ? model.phone_home : null,
    phone_work: model ? model.phone_work : null,
    phone_fax: model ? model.phone_fax : null,
    phone_cell: model ? model.phone_cell : null,
    email: model ? model.email : null,
    is_applicable: model ? model.is_applicable : null,
    education_id: model ? model.education_id : null,
  }
}
