import { ref } from "vue"
import moment from "moment"

export const namespaced = true

export const state = {
  agreementForm: null,

  agreementDate: {
    date: null,
  },
}

export const mutations = {
  CREATE_AGREEMENT_FORM(state, form) {
    state.agreementForm = form
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null
    state[dateForm][field] = value
  },
}

export const actions = {
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  createAgreementForm({ commit }, agreement) {
    commit("SET_DATE_FIELD", {
      model: agreement,
      field: "date",
      dateForm: "agreementDate",
    })

    commit("CREATE_AGREEMENT_FORM", {
      client_name: agreement.client_name,
      parents_name: agreement.parents_name,
      address: agreement.address,
      signature: agreement.signature ? agreement.signature : "",
      date: agreement.date,
    })
  },
}

export const getters = {}
