<template>
  <div class="sm:hidden block" :class="{ block: block }" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
