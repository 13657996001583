<template>
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    v-if="pagination"
  >
    <div class="flex-1 flex sm:hidden">
      <router-link
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        v-if="currentPage != 1"
        :to="to(parseInt(currentPage) - 1)"
      >
        Previous
      </router-link>
      <router-link
        v-if="pagination.has_more_pages"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        :to="to(parseInt(currentPage) + 1)"
      >
        Next
      </router-link>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">
            {{ pagination.first_item }}
          </span>
          to
          <span class="font-medium">
            {{ pagination.last_item }}
          </span>
          of
          <span class="font-medium">
            {{ pagination.total }}
          </span>
          results
        </p>
      </div>
      <div v-if="pagination.has_pages">
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <router-link
            v-if="!pagination.on_first_page"
            class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            :to="to(1)"
          >
            <BaseDoubleLeftIcon class="h-5 w-5" />
          </router-link>

          <router-link
            v-if="!pagination.on_first_page"
            :class="pageClass"
            :to="to(parseInt(currentPage) - 1)"
          >
            <span class="sr-only">Previous</span>
            <BaseLeftIcon class="h-5 w-5" />
          </router-link>

          <router-link
            v-for="page in pages"
            :class="[page === currentPage ? activePageClass : pageClass]"
            :key="`page-${page}`"
            :to="to(page)"
          >
            {{ page }}
          </router-link>

          <router-link
            v-if="pagination.has_more_pages"
            :class="pageClass"
            :to="to(parseInt(currentPage) + 1)"
          >
            <span class="sr-only">Next</span>
            <BaseRightIcon class="h-5 w-5" />
          </router-link>

          <router-link
            v-if="pagination.has_more_pages"
            class="relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            :to="to(pagination.last_page)"
          >
            <BaseDoubleRightIcon class="h-5 w-5" />
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePageClass:
        "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium",
      pageClass:
        "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium",
    }
  },
  methods: {
    to(page) {
      return {
        name: this.routeName,
        query: { ...this.$route.query, ...{ page: page } },
      }
    },
  },
  computed: {
    pages() {
      // calculate total pages
      let totalItems = this.pagination.total
      let pageSize = this.pagination.per_page

      // the maximum number of page navigation links to display, defaults to 10
      let maxPages = 10

      let totalPages = Math.ceil(totalItems / pageSize)

      // // ensure current page isn't out of range
      // if (this.currentPage < 1) {
      //   this.currentPage = 1;
      // } else if (this.currentPage > totalPages) {
      //   this.currentPage = totalPages;
      // }

      let startPage, endPage
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1
        endPage = totalPages
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
        if (this.currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1
          endPage = maxPages
        } else if (this.currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1
          endPage = totalPages
        } else {
          // current page somewhere in the middle
          startPage = this.currentPage - maxPagesBeforeCurrentPage
          endPage = this.currentPage + maxPagesAfterCurrentPage
        }
      }

      // calculate start and end item indexes
      //   let startIndex = (currentPage - 1) * pageSize;
      //   let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      )

      return pages
    },
  },
}
</script>

<style lang="scss" scoped></style>
