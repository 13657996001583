<template>
  <label class="flex items-center space-x-1">
    <input
      class="form-tick appearance-none h-4 w-4 border rounded-sm focus:outline-none"
      :class="[
        isChecked
          ? 'bg-check bg-gray-500 border-transparent'
          : 'border-gray-300',
      ]"
      disabled="true"
    />
    <span class="text-gray-700" v-if="label">
      {{ label }}
    </span>
  </label>
</template>

<script>
import { inputMixin } from "@/mixins/form.js"

export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String, Boolean],
    },
    models: {
      type: Array,
    },
  },
  mixins: [inputMixin],
  computed: {
    ids() {
      return this.models.map((item) => {
        if (item.id !== null && item.id !== false) {
          return item.id
        }
      })
    },
    isChecked() {
      return this.ids.includes(this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-check {
  background-image: url("@/assets/svg/check.svg") !important;
}
</style>
