<template>
  <BaseLabel :label="label">
    <span class="text-red-500 required-dot"> * </span>
  </BaseLabel>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
}
</script>
