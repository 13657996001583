<template>
  <footer class="bg-gray-800 w-full py-8">
    <div class="max-w-screen-xl text-sm mx-auto px-4">
      <div class="text-center text-gray-400 space-y-4">
        <div>
          The National Association for Child Development<br />
          International Headquarters<br />
          5492 S 500 E<br />
          Washington Terrace, Utah 84405<br />
          Phone:
          <a
            href="tel:+18016218606"
            class="hover:text-white hover:underline cursor-pointer"
            >+1 (801) 621-8606</a
          ><br />
        </div>
        <div class="border-t border-gray-400"></div>
        <div>
          <p>
            &copy; 1984&mdash;{{ year }} NACD International | The National
            Association for Child Development. All rights reserved.
          </p>
          <p>
            Helping kids & adults around the world achieve their innate
            potential.
          </p>
        </div>
        <div
          class="flex flex-col sm:flex-row justify-center items-center gap-4"
        >
          <AppStoreLink />
          <GooglePlayLink />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppStoreLink from "@/components/mobile/AppStoreLink.vue"
import GooglePlayLink from "@/components/mobile/GooglePlayLink.vue"

export default {
  components: { AppStoreLink, GooglePlayLink },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
