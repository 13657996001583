import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  fromPromise,
} from "@apollo/client/core"
import { createApolloProvider } from "@vue/apollo-option"
import AuthService from "@/services/AuthService.js"
import { onError } from "@apollo/client/link/error"
import { RefreshToken } from "@/graphql/mutations/auth/refreshToken.gql"

let apolloClient

let authService = new AuthService()

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message /**, locations, path */ }) => {
        let forwardP
        // console.log(
        //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        // );
        switch (message) {
          // case "validation":
          //   authService.handleNotFoundError()
          //   break
          case "Unauthorized":
            if (!isRefreshing) {
              isRefreshing = true

              forwardP = fromPromise(
                apolloClient
                  .mutate({
                    mutation: RefreshToken,
                    variables: {
                      refreshToken: {
                        refresh_token: authService.getRefreshToken(),
                      },
                    },
                  })
                  .then((response) => {
                    const { access_token, refresh_token } =
                      response.data.refreshToken
                    authService.setToken(access_token)
                    authService.setRefreshToken(refresh_token)
                    resolvePendingRequests()

                    return access_token
                  })
                  .catch(() => {
                    pendingRequests = []
                    authService.logout()
                    return forward(operation)
                  })
                  .finally(() => {
                    isRefreshing = false
                  })
              )
                .filter((value) => Boolean(value))
                .flatMap((accessToken) => {
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${accessToken}`,
                    },
                  })
                  return forward(operation)
                })
            } else {
              forwardP = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve())
                })
              )
            }

            forwardP.flatMap(() => forward(operation))
            break
        }
      })
    }

    if (networkError) {
      authService.handleNetworkError()
    }
  }
)

const authLink = new ApolloLink((operation, forward) => {
  const token = authService.getToken()
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  })

  return forward(operation)
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_URL,
})

// const cache = new InMemoryCache({
//   typePolicies: {
//     Query: {
//       fields: {
//         reEvaluation: {
//           merge(existing, incoming) {
//             return incoming
//           },
//         },
//         reEvaluationActivities: {
//           merge(existing, incoming) {
//             return incoming
//           },
//         },
//         reEvaluationDoctors: {
//           merge(existing, incoming) {
//             return incoming
//           },
//         },
//         reEvaluationClasses: {
//           merge(existing, incoming) {
//             return incoming
//           },
//         },
//         reEvaluationMedications: {
//           merge(existing, incoming) {
//             return incoming
//           },
//         },
//       },
//     },
//   },
// })

apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

// Create a provider
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      loadingKey: "loading",
      fetchPolicy: "cache-and-network",
    },
  },
})

export { apolloProvider, apolloClient }
