export const namespaced = true

export const state = {
  behaviourForm: null,
  behaviourDisorderAssignmentsForm: {},
  applicationFormBehaviourDisorders: [],
}

export const mutations = {
  CREATE_BEHAVIOUR_FORM(state, form) {
    state.behaviourForm = form
  },
  SET_APPLICATION_FORM_BEHAVIOUR_DISORDERS(state, value) {
    state.applicationFormBehaviourDisorders = value
  },
  CREATE_BEHAVIOUR_DISORDER_ASSIGNMENTS_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.behaviourDisorderAssignmentsForm[item.id] = null
    })

    models.forEach((item) => {
      state.behaviourDisorderAssignmentsForm[item.disorder_id] = item.answer_id
    })
  },
}

export const actions = {
  createBehaviourForm({ commit }, behaviour) {
    commit("CREATE_BEHAVIOUR_FORM", {
      client_behavioral_disorders: behaviour.client_behavioral_disorders,
      client_behavioral_disorders_description:
        behaviour.client_behavioral_disorders_description,
      family_behavioral_disorders: behaviour.family_behavioral_disorders,
      family_behavioral_disorders_description:
        behaviour.family_behavioral_disorders_description,
      client_positive_behaviours: behaviour.client_positive_behaviours,
      client_negative_behaviours: behaviour.client_negative_behaviours,
      client_behavioral_goals: behaviour.client_behavioral_goals,
      client_behavioral_goals_description:
        behaviour.client_behavioral_goals_description,
    })
  },
  setApplicationFormBehaviourDisorders(
    { commit, rootState },
    applicationFormBehaviourDisorders
  ) {
    commit(
      "SET_APPLICATION_FORM_BEHAVIOUR_DISORDERS",
      applicationFormBehaviourDisorders
    )

    commit("CREATE_BEHAVIOUR_DISORDER_ASSIGNMENTS_FORM", {
      allModels: applicationFormBehaviourDisorders,
      models:
        rootState.applicationForm.application.behaviourDisorderAssignments,
    })
  },
}

export const getters = {
  behaviourDisorderAssignments(state) {
    let result = []

    for (const property in state.behaviourDisorderAssignmentsForm) {
      if (
        state.behaviourDisorderAssignmentsForm[property] !== null &&
        state.behaviourDisorderAssignmentsForm[property] !== undefined
      ) {
        result.push({
          disorder_id: parseInt(property),
          answer_id: state.behaviourDisorderAssignmentsForm[property],
        })
      }
    }

    return result
  },
}
