<template>
  <div>
    <BaseRequiredLabel :label="label" v-if="label" />
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center"
        @click="$emit('icon-clicked')"
      >
        <span class="text-gray-400 sm:text-sm">
          <slot />
        </span>
      </div>
      <input
        v-bind="$attrs"
        :autofocus="autofocus"
        :class="[isInvalid ? invalidClass : activeClass]"
        class="pl-7"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
      />
    </div>
  </div>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import { inputMixin, requiredFormFieldMixin } from "@/mixins/form.js"

export default {
  emits: ["icon-clicked", "focus", "update:modelValue"],
  mixins: [inputMixin, requiredFormFieldMixin],
}
</script>

<style lang="scss" scoped></style>
