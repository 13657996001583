export const namespaced = true

export const state = {
  autosaving: false,
}

export const mutations = {
  SET_AUTOSAVING(state, autosaving) {
    state.autosaving = autosaving
  },
}

export const actions = {
  set({ commit }, value) {
    commit("SET_AUTOSAVING", value)
  },
}
