<template>
  <label class="inline-flex items-center">
    <input
      class="h-4 w-4 text-red-600"
      type="radio"
      :checked="modelValue === value"
      :value="value"
      @change="$emit('update:modelValue', value)"
      v-bind="$attrs"
    />
    <span class="ml-1 text-gray-700" v-if="label"> {{ label }} </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      name: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
