const courseActivityRoutes = [
  {
    path: "/course-activities",
    component: () =>
      import(
        /* webpackChunkName: "course-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: ":id",
        name: "CourseActivityView",
        meta: { title: "View Course Activity" },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-activity-view" */ "@/views/course-activity/View.vue"
          ),
      },
    ],
  },
]

export default courseActivityRoutes
