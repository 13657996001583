import router from "@/router/index.js"

const TOKEN = "token"
const REFRESH_TOKEN = "refresh-token"
const USER = "user"
const LOGIN_AS = "login-as"

class AuthService {
  logout() {
    this.clearAll()
    return router.replace({ name: "Login" })
  }

  handleNotFoundError() {
    router.push({ name: "404Resource", params: { resource: "model" } })
  }

  handleNetworkError() {
    router.push({ name: "NetworkError" })
  }

  setLoginAs(value) {
    localStorage.setItem(LOGIN_AS, value)
  }

  getLoginAs() {
    let result = localStorage.getItem(LOGIN_AS)
    return result ? Boolean(result) : false
  }

  removeLoginAs() {
    localStorage.removeItem(LOGIN_AS)
  }

  setToken(token) {
    localStorage.setItem(TOKEN, token)
  }

  getToken() {
    return localStorage.getItem(TOKEN)
  }

  removeToken() {
    localStorage.removeItem(TOKEN)
  }

  setRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token)
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN)
  }

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN)
  }

  setUser(user) {
    localStorage.setItem(USER, JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem(USER))
  }

  id() {
    return this.getUser().user_id
  }

  removeUser() {
    localStorage.removeItem(USER)
  }

  clearAll() {
    localStorage.clear()
  }

  isGuest() {
    return this.getToken() === null || localStorage.getItem(USER) === null
  }

  isLimbo() {
    let user = this.getUser()
    return user ? user.isLimbo : false
  }

  setData({ access_token, refresh_token, family }) {
    this.setToken(access_token)
    this.setRefreshToken(refresh_token)
    this.setUser(family)
  }
}

export default AuthService
