<template>
  <div class="bg-white shadow-lg rounded-sm">
    <div class="w-full mb-4 rounded-t-lg h-28 bg-app-green"></div>
    <div class="flex flex-col items-center -mt-20">
      <a href="#" class="relative block">
        <slot name="image" />
      </a>
      <div>
        <slot />
      </div>
    </div>
    <div class="p-4">
      <slot name="links" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
