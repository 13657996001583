<template>
  <label class="flex items-center space-x-1">
    <input
      v-bind="$attrs"
      type="checkbox"
      class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded-sm checked:bg-blue-500 checked:border-transparent focus:outline-none"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label class="text-gray-700 font-normal" v-if="label">
      {{ label }}
    </label>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      defalt: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.autoSaved {
  input {
    background-color: rgb(220 252 231) !important;
  }
}
</style>
