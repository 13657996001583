<template>
  <button
    v-bind="$attrs"
    class="flex justify-center items-center w-full transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-fuchsia-600 hover:bg-fuchsia-700 focus:ring-fuchsia-500 focus:ring-offset-fuchsia-200"
    :class="sizeClass"
  >
    <slot name="icon" />
    <slot />
  </button>
</template>

<script>
import { sizeMixin } from "@/mixins/form.js"

export default {
  mixins: [sizeMixin],
}
</script>

<style lang="scss" scoped></style>
