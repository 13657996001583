<template>
  <BaseRequiredLabel :label="label" v-if="label" />

  <textarea
    v-bind="$attrs"
    :autofocus="autofocus"
    :class="[isInvalid ? invalidClass : activeClass]"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @focus="$emit('focus')"
  ></textarea>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import { inputMixin, requiredFormFieldMixin } from "@/mixins/form.js"

export default {
  mixins: [inputMixin, requiredFormFieldMixin],
}
</script>

<style lang="scss" scoped></style>
