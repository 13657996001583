import FamilyApplicationFormHandPreferenceOthers from "@/graphql/queries/application-form-hand-preference-other/familyApplicationFormHandPreferenceOthers.gql"
import { APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_OTHER } from "@/constants/application-form.js"

import { apolloClient } from "@/apollo"

export const namespaced = true

export const state = {
  fetching: false,

  motorSkillDisordersForm: {},
  languagesForm: {},
  mathsForm: {},
  cognitivesForm: {},
  handPreferencesForm: {},
  handPreferenceOtherForm: {},

  applicationFormPhysicalMotorSkillDisorders: [],
  applicationFormLanguageSkills: [],
  applicationFormMathSkills: [],
  applicationFormCognitiveSkills: [],
  applicationFormHandPreferenceHands: [],
  applicationFormHandPreferenceAbilities: [],

  handPreferenceOthers: [],
}

export const mutations = {
  SET_FETCHING(state, value) {
    state.fetching = value
  },
  CREATE_MOTOR_SKILL_DISORDERS_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.motorSkillDisordersForm[item.id] = false
    })

    models.forEach((item) => {
      state.motorSkillDisordersForm[item.id] = true
    })
  },
  CREATE_LANGUAGES_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.languagesForm[item.id] = null
    })

    models.forEach((item) => {
      state.languagesForm[item.language_skill_id] = item.answer_id
    })
  },
  CREATE_MATHS_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.mathsForm[item.id] = null
    })

    models.forEach((item) => {
      state.mathsForm[item.math_skill_id] = item.answer_id
    })
  },
  CREATE_COGNITIVES_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.cognitivesForm[item.id] = null
    })

    models.forEach((item) => {
      state.cognitivesForm[item.cognitive_skill_id] = item.answer_id
    })
  },
  SET_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS(state, value) {
    state.applicationFormPhysicalMotorSkillDisorders = value
  },
  SET_APPLICATION_FORM_LANGUAGE_SKILLS(state, value) {
    state.applicationFormLanguageSkills = value
  },
  SET_APPLICATION_FORM_MATH_SKILLS(state, value) {
    state.applicationFormMathSkills = value
  },
  SET_APPLICATION_FORM_COGNITIVE_SKILLS(state, value) {
    state.applicationFormCognitiveSkills = value
  },
  SET_APPLICATION_FORM_HAND_PREFERENCE_HANDS(state, value) {
    state.applicationFormHandPreferenceHands = value
  },
  SET_APPLICATION_FORM_HAND_PREFERENCE_ABILITIES(state, value) {
    state.applicationFormHandPreferenceAbilities = value
  },
  CREATE_HAND_PREFERENCES_FORM(state, { allModels, models }) {
    allModels.forEach((item) => {
      state.handPreferencesForm[item.id] = null
    })

    models.forEach((item) => {
      state.handPreferencesForm[item.ability_id] = item.hand_id
    })
  },
  SET_HAND_PREFERENCE_OTHERS(state, handPreferenceOthers) {
    state.handPreferenceOthers = handPreferenceOthers
  },
}

export const actions = {
  refetch({ commit, dispatch, rootState }) {
    commit("SET_FETCHING", true)

    return apolloClient
      .query({
        query: FamilyApplicationFormHandPreferenceOthers,
        fetchPolicy: "no-cache",
        variables: {
          form_id: rootState.applicationForm.application.id,
        },
      })
      .then((response) => {
        dispatch(
          "setHandPreferenceOthers",
          response.data.familyApplicationFormHandPreferenceOthers
        )
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_FETCHING", false)
      })
  },
  setHandPreferenceOthers({ commit }, handPreferenceOthers) {
    commit("SET_HAND_PREFERENCE_OTHERS", handPreferenceOthers)
  },
  setApplicationFormPhysicalMotorSkillDisorders(
    { commit, rootState },
    applicationFormPhysicalMotorSkillDisorders
  ) {
    commit(
      "SET_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS",
      applicationFormPhysicalMotorSkillDisorders
    )

    commit("CREATE_MOTOR_SKILL_DISORDERS_FORM", {
      allModels: applicationFormPhysicalMotorSkillDisorders,
      models: rootState.applicationForm.application.motorSkillDisorders,
    })
  },
  setApplicationFormLanguageSkills(
    { commit, rootState },
    applicationFormLanguageSkills
  ) {
    commit(
      "SET_APPLICATION_FORM_LANGUAGE_SKILLS",
      applicationFormLanguageSkills
    )

    commit("CREATE_LANGUAGES_FORM", {
      allModels: applicationFormLanguageSkills,
      models: rootState.applicationForm.application.languages,
    })
  },
  setApplicationFormMathSkills(
    { commit, rootState },
    applicationFormMathSkills
  ) {
    commit("SET_APPLICATION_FORM_MATH_SKILLS", applicationFormMathSkills)

    commit("CREATE_MATHS_FORM", {
      allModels: applicationFormMathSkills,
      models: rootState.applicationForm.application.maths,
    })
  },
  setApplicationFormCognitiveSkills(
    { commit, rootState },
    applicationFormCognitiveSkills
  ) {
    commit(
      "SET_APPLICATION_FORM_COGNITIVE_SKILLS",
      applicationFormCognitiveSkills
    )

    commit("CREATE_COGNITIVES_FORM", {
      allModels: applicationFormCognitiveSkills,
      models: rootState.applicationForm.application.cognitives,
    })
  },
  setApplicationFormHandPreferenceHands(
    { commit },
    applicationFormHandPreferenceHands
  ) {
    commit(
      "SET_APPLICATION_FORM_HAND_PREFERENCE_HANDS",
      applicationFormHandPreferenceHands
    )
  },
  setApplicationFormHandPreferenceAbilities(
    { commit, rootState },
    applicationFormHandPreferenceAbilities
  ) {
    commit(
      "SET_APPLICATION_FORM_HAND_PREFERENCE_ABILITIES",
      applicationFormHandPreferenceAbilities
    )

    commit("CREATE_HAND_PREFERENCES_FORM", {
      allModels: applicationFormHandPreferenceAbilities,
      models: rootState.applicationForm.application.handPreferences,
    })
  },
}

export const getters = {
  skills(state) {
    return [
      {
        title: "Language and Reading Skills",
        field: "language",
        models: state.applicationFormLanguageSkills,
        form: state.languagesForm,
      },
      {
        title: "Math Skills",
        field: "math",
        models: state.applicationFormMathSkills,
        form: state.mathsForm,
      },
      {
        title: "Cognitive",
        field: "cognitive",
        models: state.applicationFormCognitiveSkills,
        form: state.cognitivesForm,
      },
    ]
  },
  motorSkillDisorders(state) {
    let result = []

    for (const property in state.motorSkillDisordersForm) {
      if (state.motorSkillDisordersForm[property]) {
        result.push({ disorder_id: parseInt(property) })
      }
    }

    return result
  },
  languages(state) {
    let result = []

    for (const property in state.languagesForm) {
      if (
        state.languagesForm[property] !== null &&
        state.languagesForm[property] !== undefined
      ) {
        result.push({
          language_skill_id: parseInt(property),
          answer_id: state.languagesForm[property],
        })
      }
    }

    return result
  },
  maths(state) {
    let result = []

    for (const property in state.mathsForm) {
      if (
        state.mathsForm[property] !== null &&
        state.mathsForm[property] !== undefined
      ) {
        result.push({
          math_skill_id: parseInt(property),
          answer_id: state.mathsForm[property],
        })
      }
    }

    return result
  },
  cognitives(state) {
    let result = []

    for (const property in state.cognitivesForm) {
      if (
        state.cognitivesForm[property] !== null &&
        state.cognitivesForm[property] !== undefined
      ) {
        result.push({
          cognitive_skill_id: parseInt(property),
          answer_id: state.cognitivesForm[property],
        })
      }
    }

    return result
  },
  isOtherPhysicalMotorSkill(state) {
    return state.motorSkillDisordersForm[
      APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_OTHER
    ]
  },
  handPreferences(state) {
    let result = []

    for (const property in state.handPreferencesForm) {
      if (
        state.handPreferencesForm[property] !== null &&
        state.handPreferencesForm[property] !== undefined
      ) {
        result.push({
          ability_id: parseInt(property),
          hand_id: state.handPreferencesForm[property],
        })
      }
    }

    return result
  },
}
