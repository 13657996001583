<template>
  <div class="sm:block" :class="{ hidden: hidden }" aria-hidden="true">
    <div :class="baseClass" v-bind="$attrs">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
    baseClass: {
      type: String,
      default: "py-5",
    },
  },
}
</script>

<style lang="scss" scoped></style>
