import FamilyApplicationFormMedicalHistoryTestings from "@/graphql/queries/application-form-medical-history-testing/familyApplicationFormMedicalHistoryTestings.gql"
import { apolloClient } from "@/apollo"

export const namespaced = true

export const state = {
  fetching: false,

  medicalHistoryForm: null,
  medicalHistoryTestings: [],
}
export const mutations = {
  CREATE_MEDICAL_HISTORY_FORM(state, value) {
    state.medicalHistoryForm = value
  },
  SET_MEDICAL_HISTORY_TESTINGS(state, medicalHistoryTestings) {
    state.medicalHistoryTestings = medicalHistoryTestings
  },
  SET_FETCHING(state, value) {
    state.fetching = value
  },
}
export const actions = {
  createMedicalHistoryForm({ commit }, medicalHistory) {
    commit("CREATE_MEDICAL_HISTORY_FORM", {
      family_physician: medicalHistory.family_physician,
      telephone: medicalHistory.telephone,
      address: medicalHistory.address,
      birth_weight_lbs: medicalHistory.birth_weight_lbs,
      birth_weight_oz: medicalHistory.birth_weight_oz,
      pregnancy_length: medicalHistory.pregnancy_length,
      pregnancy_complications: medicalHistory.pregnancy_complications,
      pregnancy_complications_description:
        medicalHistory.pregnancy_complications_description,
      head_brain_injury: medicalHistory.head_brain_injury,
      head_brain_injury_description:
        medicalHistory.head_brain_injury_description,
      head_brain_injury_date: medicalHistory.head_brain_injury_date,
      surgeries: medicalHistory.surgeries,
      surgeries_description: medicalHistory.surgeries_description,
      seizures: medicalHistory.seizures,
      seizures_frequency: medicalHistory.seizures_frequency,
      seizures_length: medicalHistory.seizures_length,
      seizures_type: medicalHistory.seizures_type,
      seizures_medications: medicalHistory.seizures_medications,
      seizures_medications_list: medicalHistory.seizures_medications_list,
      seizures_medications_previously:
        medicalHistory.seizures_medications_previously,
      seizures_medications_previously_list:
        medicalHistory.seizures_medications_previously_list,
      other_medications: medicalHistory.other_medications,
      other_medications_list: medicalHistory.other_medications_list,
      medical_problems: medicalHistory.medical_problems,
      medical_problems_list: medicalHistory.medical_problems_list,
      broken_limbs: medicalHistory.broken_limbs,
      broken_limbs_list: medicalHistory.broken_limbs_list,
    })
  },
  setMedicalHistoryTestings({ commit }, medicalHistoryTestings) {
    commit("SET_MEDICAL_HISTORY_TESTINGS", medicalHistoryTestings)
  },
  refetch({ commit, dispatch, rootState }) {
    commit("SET_FETCHING", true)

    return apolloClient
      .query({
        query: FamilyApplicationFormMedicalHistoryTestings,
        fetchPolicy: "no-cache",
        variables: {
          history_id: rootState.applicationForm.application.id,
        },
      })
      .then((response) => {
        dispatch(
          "setMedicalHistoryTestings",
          response.data.familyApplicationFormMedicalHistoryTestings
        )
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_FETCHING", false)
      })
  },
}
export const getters = {}
