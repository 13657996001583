<template>
  <LimboNavigation />
  <router-view />
  <Footer />
</template>

<script>
import LimboNavigation from "@/views/layouts/LimboNavigation.vue"
import Footer from "@/views/layouts/Footer.vue"

export default {
  components: {
    LimboNavigation,
    Footer,
  },
}
</script>
