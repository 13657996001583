<template>
  <div
    class="rounded-lg shadow-lg bg-white"
    aria-orientation="vertical"
    aria-labelledby="user-menu-button"
    tabindex="-1"
  >
    <header class="text-gray-800 text-xl p-5 text-center">
      {{ label }}
    </header>

    <div class="p-3">
      <div v-for="link in links" :key="`link-${link.id}`">
        <router-link
          v-if="link.isEnabled"
          :to="link.to"
          :class="btnEnabledClass"
        >
          {{ link.label }}
        </router-link>
        <div v-else :class="btnDisabledClass">
          {{ link.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Actions",
    },
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      btnClass:
        "block px-4 py-3 text-sm text-gray-800 text-center break-words my-2",
    }
  },
  computed: {
    btnEnabledClass() {
      return `${this.btnClass} bg-amber-100 hover:bg-amber-200`
    },
    btnDisabledClass() {
      return `${this.btnClass} bg-gray-200 cursor-not-allowed`
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.active {
  &:hover {
    background: $color-orange;
    color: rgba(255, 255, 255);
    font-weight: bold;
  }

  color: rgba(255, 255, 255);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  // border-radius: 0.375rem;
  cursor: pointer;
  background: $color-orange;
}
</style>
