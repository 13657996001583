<template>
  <BaseAlert
    v-for="notification in notifications"
    class="mb-4"
    :notification="notification"
    :key="notification.id"
    @close="close"
  >
    {{ notification.message }}
  </BaseAlert>
</template>

<script>
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    close(notification) {
      this.$emit("close", notification)
    },
  },
}
</script>

<style lang="scss" scoped></style>
