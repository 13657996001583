import FamilyApplicationFormChildInfos from "@/graphql/queries/application-form-child-info/familyApplicationFormChildInfos.gql"
import { apolloClient } from "@/apollo"

export const namespaced = true

export const state = {
  fetching: false,
}

export const mutations = {
  SET_FETCHING(state, value) {
    state.fetching = value
  },
}

export const actions = {
  refetch({ commit, dispatch, rootState }) {
    commit("SET_FETCHING", true)

    return apolloClient
      .query({
        query: FamilyApplicationFormChildInfos,
        fetchPolicy: "no-cache",
        variables: {
          form_id: rootState.applicationForm.application.id,
        },
      })
      .then((response) => {
        dispatch(
          "applicationForm/setChildInfos",
          response.data.familyApplicationFormChildInfos,
          { root: true }
        )
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_FETCHING", false)
      })
  },
}

export const getters = {}
