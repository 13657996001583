<template>
  <div
    class="container flex items-center text-white text-sm font-bold px-3 py-3 relative"
    :class="className"
    role="alert"
  >
    <div class="flex-shrink w-4 h-4">
      <BaseInfoIcon v-if="type === 'info'" class="text-white" />
      <BaseErrorIcon v-if="type === 'error'" class="text-white" />
      <BaseSuccessIcon v-if="type === 'success'" class="text-white" />
    </div>

    <div class="flex-1 overflow-y-auto break-words px-2">
      <slot />
    </div>
    <button class="flex-shrink h-6 w-6" @click="$emit('close', notification)">
      <BaseCloseIcon class="text-white" />
    </button>
  </div>
</template>

<script>
import { messageMixin } from "@/mixins/message.js"

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  mixins: [messageMixin],
  computed: {
    type() {
      return this.notification.type
    },
  },
}
</script>
