<template>
  <div @click="away">
    <BaseFlash
      v-for="notification in notifications"
      :notification="notification"
      :key="notification.id"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  methods: {
    away() {
      this.$store.dispatch("closeMobileDropdown")
      this.$store.dispatch("closeProfileDropdown")
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("notification", ["notifications"]),
  },
}
</script>
<style lang="scss">
@import "@/assets/styles/global.scss";
</style>
