import { ref } from "vue"
import moment from "moment"

export const namespaced = true

export const state = {
  goalForm: null,

  goalDate: {
    date_1: null,
    date_2: null,
  },
}

export const mutations = {
  CREATE_GOAL_FORM(state, form) {
    state.goalForm = form
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null
    state[dateForm][field] = value
  },
}

export const actions = {
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  createGoalForm({ commit }, goal) {
    commit("SET_DATE_FIELD", {
      model: goal,
      field: "date_1",
      dateForm: "goalDate",
    })

    commit("SET_DATE_FIELD", {
      model: goal,
      field: "date_2",
      dateForm: "goalDate",
    })

    commit("CREATE_GOAL_FORM", {
      goals: goal.goals,
      goals_professional: goal.goals_professional,
      goals_academics: goal.goals_academics,
      goals_personal: goal.goals_personal,
      implementer: goal.implementer,
      they_can_work: goal.they_can_work,
      other_can_work: goal.other_can_work,
      signature_1: goal.signature_1 ? goal.signature_1 : "",
      date_1: goal.date_1,
      signature_2: goal.signature_2,
      date_2: goal.date_2,
    })
  },
}

export const getters = {}
