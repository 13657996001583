import FamilyApplicationFormHealthPhysicalActivities from "@/graphql/queries/application-form-health-physical-activity/familyApplicationFormHealthPhysicalActivities.gql"
import { apolloClient } from "@/apollo"

import {
  APPLICATION_FORM_TIME_PERIOD_EXCESSIVE,
  APPLICATION_FORM_TIME_PERIOD_DAILY,
  APPLICATION_FORM_TIME_PERIOD_WEEKLY,
  APPLICATION_FORM_TIME_PERIOD_RARELY,
  APPLICATION_FORM_TIME_PERIOD_NEVER,
  APPLICATION_FORM_SPECIALIST_COUNSELOR,
  APPLICATION_FORM_SPECIALIST_OTHER,
} from "@/constants/application-form.js"

export const namespaced = true

export const state = {
  fetching: false,

  healthForm: null,

  excessiveProductForm: {},
  dailyProductForm: {},
  weeklyProductForm: {},
  rarelyProductForm: {},
  neverProductForm: {},
  specialistForm: {},
  eyeDiseaseForm: {},

  applicationFormHealthSpecialists: [],
  applicationFormHealthEyeDiseases: [],
  applicationFormHealthTimePeriods: [],
  applicationFormHealthProducts: [],
  physicalActivities: [],

  tinnitusOptions: [
    { value: true, label: "Continuous" },
    { value: false, label: "Intermittent" },
  ],
  foodAllergyOptions: [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
    { value: 3, label: "Never Tested" },
  ],
  earInfectionOptions: [
    { value: 1, label: "left" },
    { value: 2, label: "right" },
    { value: 3, label: "both" },
  ],
}

export const mutations = {
  CREAET_HEALTH_FORM(state, form) {
    state.healthForm = form
  },
  CREATE_EYE_DISEASE_FORM(state, { allEyeDiseases, eyeDiseases }) {
    allEyeDiseases.forEach((item) => {
      state.eyeDiseaseForm[item.id] = false
    })

    eyeDiseases.forEach((item) => {
      state.eyeDiseaseForm[item.id] = true
    })
  },
  CREATE_SPECIALIST_FORM(state, { allSpecialists, specialists }) {
    allSpecialists.forEach((item) => {
      state.specialistForm[item.id] = false
    })

    specialists.forEach((item) => {
      state.specialistForm[item.id] = true
    })
  },
  CREATE_EXCESSIVE_PRODUCT_FORM(state, { allProducts, productPeriods }) {
    allProducts.forEach((item) => {
      state.excessiveProductForm[item.id] = false
    })

    productPeriods.forEach((item) => {
      if (item.time_period_id === APPLICATION_FORM_TIME_PERIOD_EXCESSIVE) {
        state.excessiveProductForm[item.product_id] = true
      }
    })
  },
  CREATE_DAILY_PRODUCT_FORM(state, { allProducts, productPeriods }) {
    allProducts.forEach((item) => {
      state.dailyProductForm[item.id] = false
    })

    productPeriods.forEach((item) => {
      if (item.time_period_id === APPLICATION_FORM_TIME_PERIOD_DAILY) {
        state.dailyProductForm[item.product_id] = true
      }
    })
  },
  CREATE_WEEKLY_PRODUCT_FORM(state, { allProducts, productPeriods }) {
    allProducts.forEach((item) => {
      state.weeklyProductForm[item.id] = false
    })

    productPeriods.forEach((item) => {
      if (item.time_period_id === APPLICATION_FORM_TIME_PERIOD_WEEKLY) {
        state.weeklyProductForm[item.product_id] = true
      }
    })
  },
  CREATE_RARELY_PRODUCT_FORM(state, { allProducts, productPeriods }) {
    allProducts.forEach((item) => {
      state.rarelyProductForm[item.id] = false
    })

    productPeriods.forEach((item) => {
      if (item.time_period_id === APPLICATION_FORM_TIME_PERIOD_RARELY) {
        state.rarelyProductForm[item.product_id] = true
      }
    })
  },
  CREATE_NEVER_PRODUCT_FORM(state, { allProducts, productPeriods }) {
    allProducts.forEach((item) => {
      state.neverProductForm[item.id] = false
    })

    productPeriods.forEach((item) => {
      if (item.time_period_id === APPLICATION_FORM_TIME_PERIOD_NEVER) {
        state.neverProductForm[item.product_id] = true
      }
    })
  },
  SET_APPLICATION_FORM_HEALTH_PRODUCTS(state, value) {
    state.applicationFormHealthProducts = value
  },
  SET_APPLICATION_FORM_HEALTH_TIME_PERIODS(state, value) {
    state.applicationFormHealthTimePeriods = value
  },
  SET_APPLICATION_FORM_HEALTH_EYE_DISEASES(state, value) {
    state.applicationFormHealthEyeDiseases = value
  },
  SET_APPLICATION_FORM_HEALTH_SPECIALISTS(state, value) {
    state.applicationFormHealthSpecialists = value
  },
  SET_PHYSICAL_ACTIVITIES(state, value) {
    state.physicalActivities = value
  },
  SET_FETCHING(state, value) {
    state.fetching = value
  },
}

export const actions = {
  setPhysicalActivities({ commit }, physicalActivities) {
    commit("SET_PHYSICAL_ACTIVITIES", physicalActivities)
  },
  setApplicationFormHealthProducts(
    { commit, rootState },
    applicationFormHealthProducts
  ) {
    commit(
      "SET_APPLICATION_FORM_HEALTH_PRODUCTS",
      applicationFormHealthProducts
    )

    let productPeriods = rootState.applicationForm.application.productPeriods

    commit("CREATE_EXCESSIVE_PRODUCT_FORM", {
      allProducts: applicationFormHealthProducts,
      productPeriods,
    })
    commit("CREATE_DAILY_PRODUCT_FORM", {
      allProducts: applicationFormHealthProducts,
      productPeriods,
    })
    commit("CREATE_WEEKLY_PRODUCT_FORM", {
      allProducts: applicationFormHealthProducts,
      productPeriods,
    })
    commit("CREATE_RARELY_PRODUCT_FORM", {
      allProducts: applicationFormHealthProducts,
      productPeriods,
    })
    commit("CREATE_NEVER_PRODUCT_FORM", {
      allProducts: applicationFormHealthProducts,
      productPeriods,
    })
  },
  setApplicationFormHealthTimePeriods(
    { commit },
    applicationFormHealthTimePeriods
  ) {
    commit(
      "SET_APPLICATION_FORM_HEALTH_TIME_PERIODS",
      applicationFormHealthTimePeriods
    )
  },
  setApplicationFormHealthEyeDiseases(
    { commit, rootState },
    applicationFormHealthEyeDiseases
  ) {
    commit(
      "SET_APPLICATION_FORM_HEALTH_EYE_DISEASES",
      applicationFormHealthEyeDiseases
    )

    commit("CREATE_EYE_DISEASE_FORM", {
      allEyeDiseases: applicationFormHealthEyeDiseases,
      eyeDiseases: rootState.applicationForm.application.eyeDiseases,
    })
  },
  setApplicationFormHealthSpecialists(
    { commit, rootState },
    applicationFormHealthSpecialists
  ) {
    commit(
      "SET_APPLICATION_FORM_HEALTH_SPECIALISTS",
      applicationFormHealthSpecialists
    )
    commit("CREATE_SPECIALIST_FORM", {
      allSpecialists: applicationFormHealthSpecialists,
      specialists: rootState.applicationForm.application.specialists,
    })
  },
  createHealthForm({ commit }, health) {
    commit("CREAET_HEALTH_FORM", {
      nursed: health.nursed,
      nursed_age: health.nursed_age,
      diet: health.diet,
      dietary_supplements_vitamins: health.dietary_supplements_vitamins,
      food_allergy_id: health.food_allergy_id,
      food_allergy_description: health.food_allergy_description,
      allergies: health.allergies,
      allergies_description: health.allergies_description,
      food_cravings: health.food_cravings,
      picky_eater: health.picky_eater,
      overeats: health.overeats,
      poor_appetite: health.poor_appetite,
      colds: health.colds,
      ear_infections: health.ear_infections,
      ear_infection_id: health.ear_infection_id,
      ear_infections_times: health.ear_infections_times,
      ear_infections_period_of_time: health.ear_infections_period_of_time,
      tinnitus: health.tinnitus,
      tinnitus_ear_id: health.tinnitus_ear_id,
      tinnitus_continuous: health.tinnitus_continuous,
      hearing_loss: health.hearing_loss,
      hearing_loss_ear_id: health.hearing_loss_ear_id,
      hearing_loss_degree: health.hearing_loss_degree,
      hypersensitive_hearing: health.hypersensitive_hearing,
      tympanogram: health.tympanogram,
      tympanogram_results: health.tympanogram_results,
      eye_examination: health.eye_examination,
      glasses_lenses: health.glasses_lenses,
      glasses_lenses_prescription: health.glasses_lenses_prescription,
      vision_therapy: health.vision_therapy,
      vision_therapy_comment: health.vision_therapy_comment,
      sleep_time_from: health.sleep_time_from,
      sleep_time_to: health.sleep_time_to,
      naps_time_from: health.naps_time_from,
      naps_time_to: health.naps_time_to,
      seeing_specialist: health.seeing_specialist,
      specialist_other_description: health.specialist_other_description,
      specialist_counselor_description: health.specialist_counselor_description,
      other_health_problems: health.other_health_problems,
      other_health_problems_list: health.other_health_problems_list,
    })
  },
  refetch({ commit, dispatch, rootState }) {
    commit("SET_FETCHING", true)

    return apolloClient
      .query({
        query: FamilyApplicationFormHealthPhysicalActivities,
        fetchPolicy: "no-cache",
        variables: {
          form_id: rootState.applicationForm.application.id,
        },
      })
      .then((response) => {
        dispatch(
          "setPhysicalActivities",
          response.data.familyApplicationFormHealthPhysicalActivities
        )
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_FETCHING", false)
      })
  },
}

export const getters = {
  excessiveProducts(state) {
    let result = []

    for (const property in state.excessiveProductForm) {
      if (state.excessiveProductForm[property]) {
        result.push({
          product_id: parseInt(property),
          time_period_id: APPLICATION_FORM_TIME_PERIOD_EXCESSIVE,
        })
      }
    }

    return result
  },
  dailyProducts(state) {
    let result = []

    for (const property in state.dailyProductForm) {
      if (state.dailyProductForm[property]) {
        result.push({
          product_id: parseInt(property),
          time_period_id: APPLICATION_FORM_TIME_PERIOD_DAILY,
        })
      }
    }
    return result
  },
  weeklyProducts(state) {
    let result = []

    for (const property in state.weeklyProductForm) {
      if (state.weeklyProductForm[property]) {
        result.push({
          product_id: parseInt(property),
          time_period_id: APPLICATION_FORM_TIME_PERIOD_WEEKLY,
        })
      }
    }

    return result
  },
  rarelyProducts(state) {
    let result = []

    for (const property in state.rarelyProductForm) {
      if (state.rarelyProductForm[property]) {
        result.push({
          product_id: parseInt(property),
          time_period_id: APPLICATION_FORM_TIME_PERIOD_RARELY,
        })
      }
    }

    return result
  },
  neverProducts(state) {
    let result = []

    for (const property in state.neverProductForm) {
      if (state.neverProductForm[property]) {
        result.push({
          product_id: parseInt(property),
          time_period_id: APPLICATION_FORM_TIME_PERIOD_NEVER,
        })
      }
    }

    return result
  },
  productPeriods(state, getters) {
    return [
      ...getters.excessiveProducts,
      ...getters.dailyProducts,
      ...getters.weeklyProducts,
      ...getters.rarelyProducts,
      ...getters.neverProducts,
    ]
  },
  eyeDiseases(state) {
    let result = []

    for (const property in state.eyeDiseaseForm) {
      if (state.eyeDiseaseForm[property]) {
        result.push({ eye_disease_id: parseInt(property) })
      }
    }

    return result
  },
  specialists(state) {
    let result = []

    for (const property in state.specialistForm) {
      if (state.specialistForm[property]) {
        result.push({ specialist_id: parseInt(property) })
      }
    }

    return result
  },
  isSpecialistCounselor(state) {
    return state.specialistForm[APPLICATION_FORM_SPECIALIST_COUNSELOR]
  },
  isSpecialistOther(state) {
    return state.specialistForm[APPLICATION_FORM_SPECIALIST_OTHER]
  },
}
