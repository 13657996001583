<template>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script>
import Navigation from "@/views/layouts/Navigation.vue"
import Footer from "@/views/layouts/Footer.vue"

export default {
  components: {
    Navigation,
    Footer,
  },
}
</script>
