<template>
  <BaseRequiredLabel :label="label" v-if="label" />

  <div class="relative" v-bind="$attrs">
    <button
      type="button"
      :class="[isInvalid ? invalidClass : activeClass]"
      @click="show = !show"
      @focus="$emit('focus')"
    >
      <span class="flex items-center">
        <template v-if="selectedItem">
          <img
            :src="selectedItem.img"
            alt="person"
            class="flex-shrink-0 h-6 w-6 rounded-full"
          />
          <span class="ml-3 block truncate"> {{ selectedItem.name }} </span>
        </template>
        <template v-else>
          <span class="block truncate">
            {{ defaultOption }}
          </span>
        </template>
      </span>
      <span
        class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
      >
        <BaseArrowUpDownIcon class="h-5 w-5 text-gray-400" />
      </span>
    </button>
    <div
      class="absolute mt-1 w-full z-50 rounded-md bg-white shadow-lg"
      v-show="show"
    >
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <li
          id="listbox-item-0"
          role="option"
          class="text-gray-900 cursor-default hover:bg-blue-500 hover:text-white select-none relative py-2 pl-3 pr-9"
          @click="select('')"
        >
          <div class="flex items-center">
            <span
              class="block font-normal truncate"
              :class="[modelValue === '' ? 'font-semibold' : 'font-normal']"
            >
              {{ defaultOption }}
            </span>
          </div>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-4"
            v-if="modelValue === ''"
          >
            <BaseCheckIcon class="h-5 w-5" />
          </span>
        </li>
        <li
          id="listbox-item-0"
          role="option"
          class="text-gray-900 cursor-default hover:bg-blue-500 hover:text-white select-none relative py-2 pl-3 pr-9"
          v-for="item in items"
          :key="item.id"
          @click="select(item)"
        >
          <div class="flex items-center">
            <img
              :src="item.img"
              alt="person"
              class="flex-shrink-0 h-6 w-6 rounded-full"
            />
            <span
              class="ml-3 block font-normal truncate"
              :class="[isSelected(item) ? 'font-semibold' : 'font-normal']"
            >
              {{ item.name }}
            </span>
          </div>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-4"
            v-if="isSelected(item)"
          >
            <BaseCheckIcon class="h-5 w-5" />
          </span>
        </li>
      </ul>
    </div>
  </div>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import {
  customSelectInputMixin,
  requiredFormFieldMixin,
} from "@/mixins/form.js"

export default {
  mixins: [customSelectInputMixin, requiredFormFieldMixin],
}
</script>

<style lang="scss" scoped></style>
