const LIMBO_STEP_STATUS_PENDING = 1
const LIMBO_STEP_STATUS_COMPLETE = 2
const LIMBO_STEP_STATUS_INCOMPLETE = 3

const TIME_ZONE_UTC = 1

const BACKEND_ADMIN = "admin"
const BACKEND_FAMILY = "family"

const ERROR = "error"
const SUCCESS = "success"
const INFO = "info"
const WARNING = "warning"

const NO_PROFILE_IMAGE = "/icons/no-profile.jpeg"

const CLIENT_STATUS_A = 1
const CLIENT_STATUS_B = 2
const CLIENT_STATUS_D = 3
const CLIENT_STATUS_F = 5
const CLIENT_STATUS_G = 6
const CLIENT_STATUS_NSY = 7
const CLIENT_STATUS_S = 9
const CLIENT_STATUS_V = 12
const CLIENT_STATUS_X = 13
const CLIENT_STATUS_Z = 14
const CLIENT_STATUS_NAC = 15
const CLIENT_STATUS_O = 16
const CLIENT_STATUS_SM = 17
const CLIENT_STATUS_XS = 18
const CLIENT_STATUS_T = 19
const CLIENT_STATUS_SV = 20
const CLIENT_STATUS_ACTIVE = 100
const CLIENT_STATUS_ACTIVE_NOT_VACATION = 101
const CLIENT_STATUS_INACTIVE = 102

const CLIENT_STATUSES_ACTIVE = [
  CLIENT_STATUS_A,
  CLIENT_STATUS_B,
  CLIENT_STATUS_D,
  CLIENT_STATUS_F,
  CLIENT_STATUS_NSY,
  CLIENT_STATUS_S,
  CLIENT_STATUS_O,
  CLIENT_STATUS_SM,
  CLIENT_STATUS_T,
]
const CLIENT_STATUSES_INACTIVE = [
  CLIENT_STATUS_G,
  CLIENT_STATUS_X,
  CLIENT_STATUS_Z,
  CLIENT_STATUS_NAC,
  CLIENT_STATUS_XS,
]
const CLIENT_STATUSES_VACATION = [CLIENT_STATUS_V, CLIENT_STATUS_SV]

const FAMILY_STATUS_DROP_DO_NOT_CONTACT = 2
const FAMILY_STATUS_DROP_DO_KEEP_ON_MAILING_LIST = 3
const FAMILY_STATUS_ACTIVE = 4
const FAMILY_STATUS_VACATION = 8
const FAMILY_STATUS_INITIAL = 9
const FAMILY_STATUS_NEVER_A_CLIENT = 10
const FAMILY_STATUS_RETURN_IN_THE_WORKS = 11

const FAMILY_STATUS_GROUP_ACTIVE = 100
const FAMILY_STATUS_GROUP_ACTIVE_NOT_VACATION = 101
const FAMILY_STATUS_GROUP_INACTIVE = 102

const FAMILY_STATUSES_ACTIVE = [FAMILY_STATUS_ACTIVE, FAMILY_STATUS_INITIAL]

const FAMILY_STATUSES_INACTIVE = [
  FAMILY_STATUS_DROP_DO_NOT_CONTACT,
  FAMILY_STATUS_DROP_DO_KEEP_ON_MAILING_LIST,
  FAMILY_STATUS_NEVER_A_CLIENT,
]

const FAMILY_STATUSES_VACATION = [FAMILY_STATUS_VACATION]

const FAMILY_STATUSES_SPECIAL = [FAMILY_STATUS_RETURN_IN_THE_WORKS]

const ACTIVITY_VIDEO_LINK = 1
const ACTIVITY_HANDOUT_LINK = 2
const ACTIVITY_PURCHASE_LINK = 3

// Developmental Profile Categories

const TACTILE_FUNCTION = 1
const AUDITORY_FUNCTION = 2
const VISUAL_FUNCTION = 3
const FINE_MOTOR = 4
const EXPRESSIVE_LANGUAGE = 5
const GROSS_MOTOR = 6

// Developmental Profile Statuses
const COMPLETE = 1
const INCOMPLETE = 2
const UNACHIEVED = 3

// Re-Evaluation Statuses
const RE_EVALUATION_DRAFT = 1
const RE_EVALUATION_ACTIVE = 2
const RE_EVALUATION_ARCHIVED = 3
const RE_EVALUATION_ASSISTANT_OTHER = 5

const TRACK_CREATE = 1
const TRACK_UPDATE = 2
const TRACK_DELETE = 3
const TRACK_SORT = 4
const TRACK_CHANGE_PASSWORD = 5
const TRACK_UPLOAD = 6
const TRACK_EXPORT = 7
const TRACK_RESET_PASSWORD = 8
const TRACK_SEND_EMAIL = 9

const TRACK_ACTION_CREATE = [TRACK_CREATE, TRACK_UPLOAD, TRACK_EXPORT]

const TRACK_ACTION_UPDATE = [
  TRACK_UPDATE,
  TRACK_SORT,
  TRACK_CHANGE_PASSWORD,
  TRACK_RESET_PASSWORD,
]

const TRACK_ACTION_DELETE = [TRACK_DELETE]

const TRACK_ACTION_MISC = [TRACK_SEND_EMAIL]

const CLIENTS_BY_CHAPTER = 1
const FAMILIES_BY_CHAPTER = 2
const CLIENTS_BY_STATUS = 3
const CLIENTS_BY_COACH = 4
const FAMILIES_BY_COACH = 5
const CLIENTS_BY_EVALUATOR = 6
const FAMILIES_BY_EVALUATOR = 7
const CLIENTS_BY_PROGRAM_CODE = 8
const CLIENTS_HOMESCHOOLED = 9

const REPORT_STATUS_ALL = 1
const REPORT_STATUS_ALL_ACTIVE = 2
const REPORT_STATUS_ACTIVE_NOT_VACATION = 3
const REPORT_STATUS_INACTIVE = 4

const ANNOUNCEMENT_TYPE_ALL = 1
const ANNOUNCEMENT_TYPE_FAMILY = 2
const ANNOUNCEMENT_TYPE_LIMBO = 3
const ANNOUNCEMENT_TYPE_STAFF = 4
const ANNOUNCEMENT_TYPE_ACADEMY = 5
const ANNOUNCEMENT_TYPE_MOBILE = 6
const ANNOUNCEMENT_TYPE_IOS = 7
const ANNOUNCEMENT_TYPE_ANDROID = 8

const ANNOUNCEMENT_MESSAGE_TYPE_SUCCESS = 1
const ANNOUNCEMENT_MESSAGE_TYPE_ERROR = 2
const ANNOUNCEMENT_MESSAGE_TYPE_WARNING = 3
const ANNOUNCEMENT_MESSAGE_TYPE_INFO = 4

export {
  ANNOUNCEMENT_TYPE_ALL,
  ANNOUNCEMENT_TYPE_FAMILY,
  ANNOUNCEMENT_TYPE_LIMBO,
  ANNOUNCEMENT_TYPE_STAFF,
  ANNOUNCEMENT_TYPE_ACADEMY,
  ANNOUNCEMENT_TYPE_MOBILE,
  ANNOUNCEMENT_TYPE_IOS,
  ANNOUNCEMENT_TYPE_ANDROID,
  ANNOUNCEMENT_MESSAGE_TYPE_SUCCESS,
  ANNOUNCEMENT_MESSAGE_TYPE_ERROR,
  ANNOUNCEMENT_MESSAGE_TYPE_WARNING,
  ANNOUNCEMENT_MESSAGE_TYPE_INFO,
  LIMBO_STEP_STATUS_PENDING,
  LIMBO_STEP_STATUS_COMPLETE,
  LIMBO_STEP_STATUS_INCOMPLETE,
  TIME_ZONE_UTC,
  BACKEND_ADMIN,
  BACKEND_FAMILY,
  ERROR,
  SUCCESS,
  INFO,
  WARNING,
  NO_PROFILE_IMAGE,
  CLIENT_STATUSES_ACTIVE,
  CLIENT_STATUSES_INACTIVE,
  CLIENT_STATUSES_VACATION,
  FAMILY_STATUSES_ACTIVE,
  FAMILY_STATUSES_INACTIVE,
  FAMILY_STATUSES_VACATION,
  FAMILY_STATUSES_SPECIAL,
  FAMILY_STATUS_GROUP_ACTIVE,
  FAMILY_STATUS_GROUP_ACTIVE_NOT_VACATION,
  FAMILY_STATUS_GROUP_INACTIVE,
  ACTIVITY_VIDEO_LINK,
  ACTIVITY_HANDOUT_LINK,
  ACTIVITY_PURCHASE_LINK,
  TACTILE_FUNCTION,
  AUDITORY_FUNCTION,
  VISUAL_FUNCTION,
  FINE_MOTOR,
  EXPRESSIVE_LANGUAGE,
  GROSS_MOTOR,
  COMPLETE,
  INCOMPLETE,
  UNACHIEVED,
  RE_EVALUATION_DRAFT,
  RE_EVALUATION_ACTIVE,
  RE_EVALUATION_ARCHIVED,
  RE_EVALUATION_ASSISTANT_OTHER,
  TRACK_ACTION_CREATE,
  TRACK_ACTION_UPDATE,
  TRACK_ACTION_DELETE,
  TRACK_ACTION_MISC,
  CLIENTS_BY_CHAPTER,
  FAMILIES_BY_CHAPTER,
  CLIENTS_BY_STATUS,
  CLIENTS_BY_COACH,
  FAMILIES_BY_COACH,
  CLIENTS_BY_EVALUATOR,
  FAMILIES_BY_EVALUATOR,
  CLIENTS_BY_PROGRAM_CODE,
  CLIENTS_HOMESCHOOLED,
  REPORT_STATUS_ALL,
  REPORT_STATUS_ALL_ACTIVE,
  REPORT_STATUS_ACTIVE_NOT_VACATION,
  REPORT_STATUS_INACTIVE,
  CLIENT_STATUS_ACTIVE,
  CLIENT_STATUS_ACTIVE_NOT_VACATION,
  CLIENT_STATUS_INACTIVE,
}
