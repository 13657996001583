<template>
  <div class="tooltip">
    <slot />
    <span class="tooltiptext">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: block;
}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
  visibility: hidden;

  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 100;

  position: absolute;
  right: 0;
  bottom: 120%;
  white-space: nowrap;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 100%;
  margin-left: -14px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}
</style>
