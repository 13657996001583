<template>
  <button v-bind="$attrs" :class="className">
    <slot name="icon" />
    <slot />
  </button>
</template>

<script>
import { sizeMixin } from "@/mixins/form.js"

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
  },
  mixins: [sizeMixin],
  data() {
    return {
      baseClass:
        "flex justify-center items-center w-full transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2",
    }
  },
  computed: {
    className() {
      return `${this.baseClass} ${this.colorClassName} ${this.sizeClass}`
    },
    colorClassName() {
      let result
      switch (this.name) {
        default:
          result = "gray"
          break
        case "slate":
          result = "slate"
          break
        case "zinc":
          result = "zinc"
          break
        case "neutral":
          result = "neutral"
          break
        case "stone":
          result = "stone"
          break
        case "error":
          result = "red"
          break
        case "red":
          result = "red"
          break
        case "orange":
          result = "orange"
          break
        case "amber":
          result = "amber"
          break
        case "yellow":
          result = "yellow"
          break
        case "warning":
          result = "yellow"
          break
        case "lime":
          result = "lime"
          break
        case "secondary":
          return "text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 focus:ring-gray-500 focus:ring-offset-gray-200"
        case "success":
          result = "green"
          break
        case "green":
          result = "green"
          break
        case "emerald":
          result = "emerald"
          break
        case "teal":
          result = "teal"
          break
        case "cyan":
          result = "cyan"
          break
        case "sky":
          result = "sky"
          break
        case "info":
          result = "blue"
          break
        case "blue":
          result = "blue"
          break
        case "indigo":
          result = "indigo"
          break
        case "violet":
          result = "violet"
          break
        case "purple":
          result = "purple"
          break
        case "fuchsia":
          result = "fuchsia"
          break
        case "pink":
          result = "pink"
          break
        case "rose":
          result = "rose"
          break
      }

      return `text-white bg-${result}-600 hover:bg-${result}-700 focus:ring-${result}-500 focus:ring-offset-${result}-200 `
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.bg-orange-600 {
  background-color: $color-orange;
}
.hover\:bg-orange-700:hover {
  background-color: $color-orange-hover;
}
.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
}
</style>
