export const namespaced = true

export const state = {
  hasCredentials: false,
  username: "",
  password: "",
}

export const mutations = {
  SHOW_CREDENTIALS(state, { username, password }) {
    state.hasCredentials = true
    state.username = username
    state.password = password
  },
  HIDE_CREDENTIALS(state) {
    state.hasCredentials = false
    state.username = ""
    state.password = ""
  },
}

export const actions = {
  hide({ commit }) {
    commit("HIDE_CREDENTIALS")
  },
  show({ commit }, credentials) {
    commit("SHOW_CREDENTIALS", credentials)
  },
}
