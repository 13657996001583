<template>
  <button
    v-bind="$attrs"
    class="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
    :class="className"
  >
    <slot name="icon" />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
  },
  computed: {
    className() {
      let result
      switch (this.name) {
        default:
          result = "gray"
          break
        case "success":
          result = "green"
          break
        case "error":
          result = "red"
          break
        case "warning":
          result = "pink"
          break
        case "info":
          result = "blue"
          break
      }

      return `bg-${result}-600 hover:bg-${result}-700 focus:ring-${result}-500 focus:ring-offset-${result}-200`
      //   return `bg-${result}-600 hover:bg-${result}-700 focus:ring-${result}-500`;
    },
  },
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
}
</style>
