function props(route) {
  let orderByDefault = route.query.order_by_id || "desc"

  if (
    route.query.order_by_date ||
    route.query.order_by_type_name ||
    route.query.order_by_status_name ||
    route.query.order_by_is_visible_name
  ) {
    orderByDefault = null
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    order_by_id: orderByDefault,

    order_by_date: route.query.order_by_date || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    is_visible: route.query.is_visible
      ? parseInt(route.query.is_visible)
      : null,
    order_by_is_visible_name: route.query.order_by_is_visible_name || null,
  }
}

const clientEvaluationRoutes = [
  {
    path: "evaluations",
    name: "ClientEvaluationIndex",
    meta: { title: "Evaluation Forms" },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-index" */ "@/views/client/evaluation/Index.vue"
      ),
  },
  {
    path: "evaluations/:evaluationId",
    name: "ClientEvaluationView",
    meta: { title: "View Evaluation Form" },
    props: (route) => ({
      evaluationId: parseInt(route.params.evaluationId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-view" */ "@/views/client/evaluation/View.vue"
      ),
  },
]

export default clientEvaluationRoutes
