<template>
  <nav class="bg-gray-800 sticky top-0 z-10">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click.stop="$store.dispatch('toggleMobileDropdown')"
          >
            <span class="sr-only">Open main menu</span>
            <BaseHamburgerIcon
              class="block h-6 w-6"
              :class="[mobileHidden ? '' : 'hidden']"
            />

            <BaseHamburgerCloseIcon
              class="h-6 w-6"
              :class="[mobileHidden ? 'hidden' : '']"
            />
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block h-8 w-auto cursor-pointer"
              src="@/assets/images/logo.png"
              alt="Logo"
            />
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <div v-if="loginAs" :class="navLinkClass" @click="backToAdmin">
                Back to Admin
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <div class="ml-3 relative">
            <div>
              <button
                type="button"
                class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                @click.stop="$store.dispatch('toggleProfileDropdown')"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  v-show="isLoaded"
                  class="h-8 w-8 rounded-full object-cover"
                  :src="photo"
                  alt="Profile Photo"
                  @load="onImageLoad"
                />
                <section
                  v-show="!isLoaded"
                  class="h-8 w-8 rounded-full bg-gray-300 animate-pulse"
                ></section>
              </button>
            </div>
            <div
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="[hidden ? 'hidden' : '']"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <button
                class="inline-flex w-full justify-start items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                @click="logout"
              >
                <BaseLogoutIcon
                  class="fill-current h-5 w-5 text-gray-400 mr-2"
                />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="sm:hidden"
      :class="[mobileHidden ? 'hidden' : '']"
      id="mobile-menu"
    >
      <div class="px-2 pt-2 pb-3 space-y-1">
        <div v-if="loginAs" :class="navLinkMobileClass" @click="backToAdmin">
          Back to Admin
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      isLoaded: false,

      navLinkClass:
        "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer",
      navLinkMobileClass:
        "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium",

      navLinkActiveClass:
        "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer",
      navLinkActiveMobileClass:
        "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium",
    }
  },
  methods: {
    ...mapActions("user", ["logout", "backToAdmin"]),
    onImageLoad() {
      this.isLoaded = true
    },
  },
  computed: {
    ...mapGetters("user", ["photo"]),
    ...mapState("user", ["loginAs"]),
    ...mapState(["mobileHidden", "hidden"]),
  },
}
</script>

<style lang="scss" scoped>
.active {
  &:hover {
    background: rgba(17, 24, 39);
    color: rgba(255, 255, 255);
    font-weight: bold;
  }

  color: rgba(255, 255, 255);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background: rgba(17, 24, 39);
}

.dropdown > .active {
  &:hover {
    background: rgba(17, 24, 39);
    color: rgba(255, 255, 255);
    font-weight: bold;
  }

  color: rgba(255, 255, 255);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0 !important;
  cursor: pointer;
  background: rgba(17, 24, 39);
}
</style>
